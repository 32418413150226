import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2, FiEye } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer } from "react-toastify";
import Pagination from "../Pagination/Pagination";
import nodata from "../Assets/nodata.png";
import Loader from "../Loader/Loader";
import ViewJDModal from "../Jobs/ViewJDModal";
import NoData from "../NoData/NoData";

const JobsList = () => {
  const {
    loader,
    totalCount,
    pageCount,
    openJobs,
    setOpenJobs,
    getAllOpenJobsApi,
    viewJDModal,
    setViewJDModal,
  } = useContext(DataContext);
  const limit = 100;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [jd, setJd] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    getAllOpenJobsApi(1, limit);
  }, []);

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...openJobs].sort((a, b) => {
      const valueA = (a[property] || "").toString();
      const valueB = (b[property] || "").toString();
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });
    setOpenJobs(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  const handleViewJd = (item) => {
    setViewJDModal(true);
    setJd(item?.jobdescription);
  };
  const handleJDModalDisabled = () => {
    setViewJDModal(false);
  };
  const handleRowClick = (item) => {
    // Navigate to the desired route when the row is clicked
  //   navigate(`/apply/${item._id}?${item.assignedTo.name.trim()}`, item);
  //   // navigate(`/apply/${item._id}?${item.assignedTo.name}`, item);
  // };
  const sanitizedAssignedTo = item.assignedTo.name.replace(/\s+/g, ''); // Remove all spaces

  // Navigate to the desired route when the row is clicked
  navigate(`/apply/${item._id}?${sanitizedAssignedTo}`, item);
  }
  return (
    <>
      {viewJDModal ? (
        <ViewJDModal jd={jd} apiFunction={handleJDModalDisabled} />
      ) : null}
      <header className="bg__banner bg-bannerGradient py-8 px-4 xs:pl-4 sm:pl-4 pl-20 text-left">
        <h1 className="font-semibold text-white pb-4 xs:text-4xl sm:text-4xl text-5xl">
          Find the perfect job
        </h1>
        <p className="font-normal xs:text-base sm:text-base text-lg text-white">
          Looking for jobs? Browse our latest job openings to view & apply to
          the best job today!
        </p>
      </header>

      <div className="flex w-full mt-4">
        <div className="w-full xs:px-4 jobs__list sm:px-4 px-16 py-2 overflow-y-scroll h-[calc(100vh-12.5rem)] space-y-4">
          <div className="pt-3 flex justify-between itms-start">
            <h1 className="text-xl text-[#202223] pt-2 font-semibold">
              Active Jobs
            </h1>
            <div className="flex space-x-2 justify-between pt-2 items-start flex-wrap">
              <div className="flex gap-1">
                {/* <Link to="/import-candidates">
            <button className="border border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                Import Candidates</button>
                </Link> */}

                {/* <button className="border border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
              Export XLS
            </button>
            <Link to="/import_speak">
              <button className="border border-[#BABFC3]   px-4 py-2 text-[#202223] font-medium bg-white  rounded  text-sm">
                Import XLS
              </button>
            </Link> */}
              </div>
              {/* <div className="float-right flex">
            <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
            <input
              type="text"
              placeholder="Search"
              className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onKeyUp={(e) => {
                onKeyUpHandler(e);
              }}
            />
          </div> */}
            </div>
          </div>

          {/*--------- table list--------- */}
          {/* {globalLoader ? (
        <GlobalLoader />
        
      ) : ( */}
          {/* {openJobs.length > 0 ? ( */}
          {loader ? 
            <Loader />
           : openJobs?.length > 0 ? (
            <>
              <div className=" relative">
                <table className="w-full table-auto bg-white border-collapse text-center shadow-md rounded-lg  ">
                  <thead className="cursor-pointer ">
                    <tr className=" text-left bg-theme-light text-theme-color text-xs font-normal  ">
                      <th className=" px-4 py-3 flex items-center">
                        <div className="flex items-center gap-4">
                          <p>Job Title</p>
                          <div className="px-2">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "jobtitle",
                                  "asc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "jobtitle",
                                  "desc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Experience</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "totalExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "totalExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Positions</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "positions",
                                  "asc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "positions",
                                  "desc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className="px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Work Status</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "worknature",
                                  "asc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "worknature",
                                  "desc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4">
                        <div className="flex items-center gap-4">
                          <p>Location</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "location",
                                  "asc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "location",
                                  "desc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Priority</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "priority",
                                  "asc"
                                )}`}
                                onClick={() => sortData("priority")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "priority",
                                  "desc"
                                )}`}
                                onClick={() => sortData("priority")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Technology</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "technology",
                                  "asc"
                                )}`}
                                onClick={() => sortData("technology")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "technology",
                                  "desc"
                                )}`}
                                onClick={() => sortData("technology")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Assined To</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "assignedTo?.name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("assignedTo?.name")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "assignedTo?.name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("assignedTo?.name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th> */}
                      <th className=" px-4 py-2 text-center rounded-tr-lg">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {openJobs?.map((item) => (
                      <tr
                        key={item._id}
                        onClick={() => handleRowClick(item)}
                        className="border-t cursor-pointer text-left text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  break-word text-[16px]"
                      >
                        <td className="px-4 py-2 text-start">
                          {item?.jobtitle}
                        </td>
                        <td className="px-2 py-2">
                          {item.hasOwnProperty("totalExp")
                            ? `${item?.totalExp?.years}. ${item?.totalExp?.months}`
                            : "0.0"}
                        </td>
                        <td className="px-4 py-2">{item?.positions}</td>
                        <td className="px-4 py-2">{item?.worknature}</td>
                        <td className="px-4 py-2">{`${item?.location}`}</td>
                        {/* <td className="px-4 py-2">{item?.ctc}</td> */}
                        <td className="px-4 py-2">{item?.priority}</td>
                        <td className="px-4 py-2">
                          {item?.technology?.technologyname}
                        </td>
                        {/* <td className="px-4 py-2">{item?.assignedTo?.name}</td> */}

                        {/* <td className="px-4 py-2" onClick={()=> handleViewJd(item)}><span className="text-theme-color underline">View</span></td> */}
                        <td className="px-4 py-2">
                          <Link
                            to={`/apply/${item._id}?${item.assignedTo?.name.replace(/\s+/g, '')}`}
                            // to={`/apply/${item._id}?${item.assignedTo.replace(/\s+/g, '')}`}`}
                            state={item}
                            className="text-theme-color underline"
                          >
                            Apply
                          </Link>
                        </td>
                      </tr>
                    ))}
                   
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount} results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={getAllOpenJobsApi}
                />
              </div>
            </>
             ) : null}

{((!loader && !openJobs) || openJobs.length == 0) && <NoData />}
          {/* ) : (
            <NoData />
          )} */}
        </div>
      </div>
      <footer className="bg-theme-color text-white py-2 px-4 text-center">
        <p>Copyright © {new Date().getFullYear()} GetDevelopers</p>
      </footer>
    </>
  );
};

export default JobsList;
