import React from 'react'
import nodata from "../Assets/nodata.png";


const NoData = () => {
  return (
    <div className="flex w-full bg-white">
    <div className="w-full p-8  bg-white overflow-y-scroll h-[45vh] ">
      <div className="border border-dashed border-[#BABFC3] rounded flex flex-col justify-center items-center h-[35vh]">
        <img src={nodata} alt="nodata" />
        <span className="text-[#6D7175] text-sm font-normal">
          No Data Available
        </span>
      </div>
    </div>
  </div>
  )
}

export default NoData