import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaPlay, FaRegStopCircle } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import { toast, ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share"; // Import share buttons
import { FacebookIcon, LinkedinIcon, WhatsappIcon } from "react-share";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import ApplyPopup from "./ApplyPopup";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { CustomDropDown, CustomInput, CustomLabel, ErrorState } from "../atoms";
import { isEmailValid, isPhoneValid, isURLVaid } from "../../utils";
import { UPLOAD_ICON } from "../Assets/icons";
import VideoRecorder from "../VideoRecod/index";
import FileDragAndDrop from "../atoms/FileDragAndDrop";
import fetchUtil, { BASE_URL } from "../../utils/fetch.util";

const initialState = {
  name: "",
  linkedinprofile: "",
  resume: null,
  resumelink: "",
  phonenumber: "",
  totalExp: { years: "", months: "" },
  relevantExp: { years: "", months: "" },
  status: "64f997e6d3d3c1cadc894a75",
  joiningtime: "Immediate",
  pannumber: "",
  aadhar: null,
  pf: "",
  vendor: "",
  email: "",
  salarySlip: "",
  candidateAssigned: "",
  ctc: "",
  ectc: "",
  isSendEmail: false,
  comment: "",
  comments: [],
  fileFormat: {},
  // videoIntro: null
};

const availabilityOptions = [
  { label: "Immediate", value: "Immediate", isSelected: true },
  { label: "15 days", value: "15 days" },
  { label: "30 days", value: "30 days" },
];

const ApplyJob = () => {
  const {
    ButtonLoader,
    setButtonLoader,
    disableSave,
    applyUserJobApi,
    getOpenJobDetailsApi,
    getAllOpenJobsApi,
    openJobDetail,
    openJobs,
    globalLoader,
    applyPopup,
    setApplyPopup,
    fullScreenLoader,
    applycandidatejob,
    
  } = useContext(DataContext);

  const [isVerified, setIsVerified] = useState(false);
  const [activeTech, setActiveTech] = useState([]);
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  console.log(pathname);
  const jobid = params.jobid;

  const candidateApply = (state) => {
    var formdata = new FormData();
    formdata.append("name", state.name);
    formdata.append("linkedinprofile", state.linkedinprofile);
    formdata.append("resumelink", state.resumelink);
    formdata.append("phonenumber", state.phonenumber);
    formdata.append("rate", state.ctc);
    formdata.append("joiningtime", state.joiningtime);
    formdata.append("relevantExp[years]", state["relevantExp"].years);
    formdata.append("relevantExp[months]", state["relevantExp"].months);
    formdata.append("totalExp[years]", state["totalExp"].years);
    formdata.append("totalExp[months]", state["totalExp"].months);
    formdata.append("status", "64f997e6d3d3c1cadc894a75");
    formdata.append("primarytechnology", state?.primarytechnology);
    formdata.append("email", state.email);
    formdata.append("resume", state.resume);
    // formdata.append("fileFormat", state.fileFormat);
    state.videoIntro && formdata.append("videoIntro", state.videoIntro);
    return fetchUtil
      .POST("https://api.getdeveloper.in/job/applyCandidate/", formdata, "formData")
      .then((response) => {
        console.log(response);
        toast.success("Applied Successfully");
        setApplyPopup(true);
      })
      .catch((err) => console.error(err));
  };
  const handleSubmit = () => {
    if (pathname === "/job_apply") applycandidatejob(state);
    else applyUserJobApi(state, jobid, openJobDetail?.technology);
    // applycandidatejob(state)
    // applyUserJobApi(state, jobid, openJobDetail?.technology);
    // return console.log(state);
    // if (pathname === "/job_apply") candidateApply(state);
    // else applyUserJobApi(state, jobid, openJobDetail?.technology);
  };

  const handleClick = (id) => {
    console.log('================',id)
    getOpenJobDetailsApi(id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate(`/apply/${id}`);
  };

  useEffect(() => {
    getAllOpenJobsApi(1, 50);
    if (!(pathname === "/job_apply")) getOpenJobDetailsApi(params?.jobid);
  }, [params, pathname]);

  // useEffect(() => {
  //   const body = document.body;
  //   (applyPopup || fullScreenLoader)
  //     ? body.classList.add("overflow-hidden")
  //     : body.classList.remove("overflow-hidden");
  // }, [applyPopup]);

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const [resumeOption, setResumeOption] = useState("upload");

  const [error, setError] = useState({
    phonenumber: false,
    email: false,
    linkedinprofile: false,
    aadhar: false,
    pannumber: false,
    pf: false,
    resume: false,
    resumelink: false,
  });
  const inputRef = useRef(null);
  const [state, setState] = useState(initialState);
console.log("ssssss=====================",state)
  useEffect(() => {
    setState((prev) => ({ ...prev, relevantExp: prev.totalExp }));
  }, [state.totalExp]);

  useEffect(() => {
    if (resumeOption === "upload")
      setState((prev) => ({ ...prev, resumelink: "" }));
    if (resumeOption === "link")
      setState((prev) => ({ ...prev, resume: null }));
  }, [resumeOption]);

  useEffect(() => {
    const getAllTechnologies = async () => {
        try {
            const response = await fetch("https://api.getdeveloper.in/technology/alltechnologies");
            const dataFetching = await response.json();
            console.log(dataFetching, "dataFecthing++")
            const activateTechnology = dataFetching.result.filter((item) => item.isActive)
            setActiveTech(activateTechnology);
        } catch (err) {
            console.error(err);
        }
    };
    getAllTechnologies();
}, []);

  // useEffect(() => {
  //   getAllTechnologies();
  // }, []);

  const isFormValid = () => {
    return (
      state.name?.trim() !== "" &&
      !error.phonenumber &&
      !error.email &&
      !error.linkedinprofile &&
      state.ctc?.trim() !== "" &&
      state.totalExp.years !== "" &&
      state.totalExp.months !== "" &&
      state.relevantExp.years !== "" &&
      state.relevantExp.months !== "" &&
      state.joiningtime !== "" &&
      !error.resumelink &&
      (state.resumelink !== "" || state.resume !== null) &&
      !error.resume
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (e.target.name === "aadhar") {
      if (file.size > 1024 * 1024) {
        setError((prev) => ({ ...prev, aadhar: true }));
        return;
      }
      setState({ ...state, aadhar: file });
    } else if (e.target.name === "resume") {
      if (file.size > 1024 * 1024) {
        setError((prev) => ({ ...prev, resume: true }));
        return;
      }
      setState({ ...state, resume: file });
    }
  };

  const setStateValues = (name, value) =>
    setState((prev) => ({ ...prev, [name]: value.trim() }));
  const setErrorValueTrue = (errorKey) =>
    setError((prev) => ({ ...prev, [errorKey]: true }));
  const setErrorValueFalse = (errorKey) =>
    setError((prev) => ({ ...prev, [errorKey]: false }));

  const inputFields = ["pannumber", "pf"];

  const handleChange = (e) => {
    const val = inputFields.includes(e.target.name)
      ? e.target.value.toUpperCase()
      : e.target.value;
    setStateValues(e.target.name, val);
    if (e.target.value !== "") {
      if (e.target.name === "phonenumber") {
        if (!isPhoneValid(e.target.value)) setErrorValueTrue(e.target.name);
        else setErrorValueFalse(e.target.name);
      }
      if (e.target.name === "email") {
        if (!isEmailValid(e.target.value)) setErrorValueTrue(e.target.name);
        else setErrorValueFalse(e.target.name);
      }
      if (e.target.name === "linkedinprofile") {
        if (
          !isURLVaid(e.target.value) ||
          !e.target.value.toLowerCase().includes("linkedin")
        )
          setErrorValueTrue(e.target.name);
        else setErrorValueFalse(e.target.name);
      }
      if (e.target.name === "resumelink") {
        if (!isURLVaid(e.target.value)) setErrorValueTrue(e.target.name);
        else setErrorValueFalse(e.target.name);
      }
    } else setErrorValueFalse(e.target.name);
  };

  const handleResumeDoc = (file) => {
    const resumeFile = file[0];
    if (resumeFile?.size > 1024 * 1024) {
      setError((prev) => ({ ...prev, resume: true }));
      return;
    }
    setState((prev) => ({ ...prev, resume: resumeFile }));
  };

  const handleOptionChange = (e) => {
    setResumeOption(e.target.value);

    // to remove the other file because user can select only one
    if (e.target.value === "upload")
      setState((prev) => ({ ...prev, resumelink: "" }));
    else if (e.target.value === "drive")
      setState((prev) => ({ ...prev, resume: null }));
  };

  const fileSizeError = "Maximum file size: 1MB";
  const heroSectionText =
    pathname === "/job_apply"
      ? "Browse jobs"
      : `Apply for ${openJobDetail?.jobtitle}`;

  return (
    <>
      <ToastContainer />
      {applyPopup && <ApplyPopup />}
      {fullScreenLoader && <FullScreenLoader />}
      <header className="bg__banner bg-bannerGradient py-6 px-4 xs:pl-4 sm:pl-4 pl-20 text-left">
        <h1 className="font-semibold text-white pb-4 text-5xl">
          Find the perfect job
        </h1>
        <p className="font-normal text-lg text-white">
          Looking for jobs? Browse our latest job openings to view & apply to
          the best job today!
        </p>
      </header>
      {/* {loader ? 
            <Loader />
           : openJobDetail?.length > 0 ? (
            <> */}
      <div className="flex w-full xs:px-4 sm:px-4 md:px-4 lg:px-4 xl:px-8 px-16 bg-[#e7e7e7]">
        <div className="w-full px-0 md:px-7 py-2  space-y-3 pt-4">
          <div className="flex items-center">
            <Link to={"/jobs"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                {heroSectionText}
              </h1>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-[8fr_4fr] xl:grid-cols-[8fr_4fr] 2xl:grid-cols-[8fr_4fr] 3xl:grid-cols-[8fr_4fr] 4xl:grid-cols-[8fr_4fr] pb-4 gap-8">
            <div className="flex flex-col gap-4">
              {pathname === "/job_apply" ? (
                ""
              ) : globalLoader ? (
                <GlobalLoader />
              ) : (
                <div className="jd rounded-lg  bg-white shadow-openJobsShadow p-4">
                  <div className="flex justify-between ">
                    <div className="flex flex-col justify-center">
                      <div className="flex gap-1">
                        <span className="font-bold">Location :</span>
                        <span>{`${openJobDetail?.location}`}</span>
                      </div>
                      <div className="flex gap-1">
                        <span className="font-bold">Number of Positon :</span>
                        <span>{openJobDetail?.positions}</span>
                      </div>
                      <div className="flex gap-1">
                        <span className="font-bold">Experience Required :</span>
                        <span>
                          {openJobDetail?.hasOwnProperty("totalExp")
                            ? `${openJobDetail?.totalExp?.years}. ${openJobDetail?.totalExp?.months}`
                            : "0.0"}
                          {/* {openJobDetail?.exp_min_max} <small>(in Years)</small>{" "} */}
                        </span>
                      </div>
                      <div className="flex gap-1">
                        <span className="font-bold">Mode of Work :</span>
                        <span>{openJobDetail?.worknature}</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      <FacebookShareButton url={window.location.href}>
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <LinkedinShareButton url={window.location.href}>
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <WhatsappShareButton url={window.location.href}>
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </div>
                  </div>

                  {openJobDetail?.jobdescription && (
                    <>
                      <p className="text-lg pt-4 font-bold">Job Description</p>

                      <div className=" jd_container overflow-y-scroll relative pt-3">
                        {/* <textarea
                        readOnly
                        value={openJobDetail?.jobdescription} className="w-full jd_textarea h-full px-4 py-2 resize-none">
                      </textarea> */}
                        <ReactQuill
                          theme="snow"
                          value={openJobDetail?.jobdescription}
                          readOnly
                        />
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="h-auto rounded-lg bg-white shadow-openJobsShadow p-4">
                {/* <div className="flex flex-col gap-4"> */}
                <h2 className="font-semibold text-lg">Candidate details</h2>
                <div className="w-full container mx-[auto] my-5">
                  {pathname === "/job_apply" ? (
                    <div className="flex my-3">
                      <div className="flex w-1/2">
                        <div className="w-1/3">
                          <label htmlFor="technology" className="font-bold">
                            Select Tech :
                          </label>
                        </div>
                        <div className="w-2/3">
                          <select
                            className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md"
                            id="technology"
                            name="primarytechnology"
                            value={state?.primarytechnology}
                            onChange={(e) =>
                              setState({
                                ...state,
                                primarytechnology: e.target.value,
                              })
                            }
                          >
                            <option value={""} selected disabled>
                              technology
                            </option>
                            {activeTech.map((tech) => (
                              <option value={tech?._id}>
                                {tech?.technologyname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 px-2">
                      <CustomLabel htmlFor="name" required>
                        Name
                      </CustomLabel>
                      <CustomInput
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Please enter name"
                        value={state.name}
                        onChange={(e) =>
                          setState({
                            ...state,
                            name: toTitleCase(
                              e.target.value.replace(/[^a-zA-Z\s]/g, "")
                            ),
                          })
                        }
                      />
                      <div className="my-3">
                        <CustomLabel htmlFor="phone" required>
                          Phone
                        </CustomLabel>
                        <CustomInput
                          type="number"
                          id="phone"
                          name="phonenumber"
                          placeholder="Please enter phone number"
                          value={state.phonenumber}
                          onChange={handleChange}
                        />
                        <ErrorState
                          err={error.phonenumber}
                          errMsg={"Please enter a valid phone number"}
                        />
                      </div>
                      <div className="mb-3">
                        <CustomLabel htmlFor="email" required>
                          Email
                        </CustomLabel>
                        <CustomInput
                          type="email"
                          id="email"
                          name="email"
                          autoComplete="off"
                          placeholder="Please enter email"
                          value={state.email}
                          onChange={handleChange}
                        />
                        <ErrorState
                          err={error.email}
                          errMsg={"Please enter a valid email"}
                        />
                      </div>
                      <div className="mb-3">
                        <CustomLabel htmlFor="linkedinprofile" required>
                          LinkedIn profile
                        </CustomLabel>
                        <CustomInput
                          type="text"
                          id="linkedinprofile"
                          name="linkedinprofile"
                          autoComplete="off"
                          placeholder="Please enter linkedin profile"
                          value={state.linkedinprofile}
                          onChange={handleChange}
                        />
                        <ErrorState
                          err={error.linkedinprofile}
                          errMsg={"Please enter a valid url"}
                        />
                      </div>
                      <div className="mb-3">
                        <CustomLabel
                          htmlFor="cctc"
                          subHeading={"(in lakhs)"}
                          required
                        >
                          CCTC
                        </CustomLabel>
                        <CustomInput
                          type="number"
                          id="cctc"
                          name="ctc"
                          autoComplete="off"
                          placeholder="Please enter current CTC"
                          value={state.ctc}
                          onChange={(e) =>
                            setState({ ...state, ctc: e.target.value })
                          }
                        />
                      </div>
                      {/* <div className="mb-3">
                        <CustomLabel subHeading={'(please record a small clip)'}>A brief intro</CustomLabel>
                        <VideoRecorder setState={setState} fileKey={'videoIntro'} />
                      </div> */}
                    </div>
                    <div className="w-full md:w-1/2 px-2">
                      <div className="mb-3">
                        <CustomLabel htmlFor="availability" required>
                          Availability
                        </CustomLabel>
                        <CustomDropDown
                          id="availability"
                          name="availability"
                          value={state.joiningtime}
                          onChange={(e) =>
                            setState({ ...state, joiningtime: e.target.value })
                          }
                        >
                          {availabilityOptions.map((opt) => (
                            <option value={opt.value} selected={opt.isSelected}>
                              {opt.label}
                            </option>
                          ))}
                        </CustomDropDown>
                      </div>
                      <div className="mb-3">
                        <CustomLabel required>Total experience</CustomLabel>
                        <div className="flex w-full justify-between">
                          <div className="w-1/2 pr-2">
                            <CustomDropDown
                              id="total_year"
                              value={state.totalExp.years}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  totalExp: {
                                    ...state.totalExp,
                                    years: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="" selected disabled>
                                years
                              </option>
                              {generateOptions(0, 30, "year")}
                            </CustomDropDown>
                          </div>
                          <div className="w-1/2 pl-2">
                            <CustomDropDown
                              id="total_month"
                              value={state.totalExp.months}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  totalExp: {
                                    ...state.totalExp,
                                    months: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="" selected disabled>
                                months
                              </option>
                              {generateOptions(0, 11, "months")}
                            </CustomDropDown>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <CustomLabel required>Relevant experience</CustomLabel>
                        <div className="flex w-full justify-between">
                          <div className="w-1/2 pr-2">
                            <CustomDropDown
                              id="relevant_year"
                              value={state.relevantExp.years}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  relevantExp: {
                                    ...state.relevantExp,
                                    years: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="" selected disabled>
                                years
                              </option>
                              {generateOptions(0, 30, "year")}
                            </CustomDropDown>
                          </div>
                          <div className="w-1/2 pl-2">
                            <CustomDropDown
                              id="relevant_month"
                              value={state.relevantExp.months}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  relevantExp: {
                                    ...state.relevantExp,
                                    months: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="" selected disabled>
                                months
                              </option>
                              {generateOptions(0, 11, "months")}
                            </CustomDropDown>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <CustomLabel
                          required
                          subHeading={"(please select any one)"}
                        >
                          Resume options
                        </CustomLabel>
                        <div className="flex justify-around">
                          <div className="flext items-center justify-center">
                            <label
                              htmlFor="upload"
                              className="text-[13px] mr-[5px]"
                            >
                              {" "}
                              Upload
                            </label>
                            <input
                              type="radio"
                              id="upload"
                              value="upload"
                              name="resume_option"
                              checked={resumeOption === "upload"}
                              onChange={handleOptionChange}
                            />
                          </div>
                          <div className="flext items-center justify-center">
                            <label
                              htmlFor="drive"
                              className="text-[13px] mr-[5px]"
                            >
                              Link
                            </label>
                            <input
                              type="radio"
                              id="drive"
                              value="drive"
                              name="resume_option"
                              checked={resumeOption === "drive"}
                              onChange={handleOptionChange}
                            />
                          </div>
                        </div>
                        {
                          resumeOption === "upload" && (
                            <>
                              <FileDragAndDrop
                                onUpload={handleResumeDoc}
                                supportedFiles={".pdf, .docx, .doc"}
                              />
                              <ErrorState
                                err={error.resume}
                                errMsg={fileSizeError}
                              />
                              {state.resume && (
                                <div className="text-xs mt-2">
                                  Selected file:{" "}
                                  <span className="text-green-600 ">
                                    {state.resume.name}
                                  </span>
                                </div>
                              )}
                            </>
                          )

                          // <div className="flex justify-center items-center mt-4">
                          //   <div className="w-full flex flex-col">
                          //     <div className="border flex flex-col justify-center items-center border-gray-300 shadow-sm rounded-md text-sm text-gray-400 cursor-pointer text-center h-[4.2rem]"
                          //       onClick={() => inputRef.current.click()}>
                          //       <UPLOAD_ICON className={'mx-[auto]'} />
                          //       <p>allowed: .docx, .doc and .pdf</p>
                          //       <input
                          //         ref={inputRef}
                          //         type="file"
                          //         accept=".pdf, .docx, .doc"
                          //         name="resume"
                          //         className="hidden"
                          //         onChange={handleFileChange}
                          //       />
                          //     </div>

                          //   </div>
                          // </div>
                        }
                        {resumeOption === "drive" && (
                          <div className="mt-4">
                            <CustomInput
                              id="resumelink"
                              type="text"
                              name="resumelink"
                              value={state.resumelink}
                              onChange={handleChange}
                            />
                            <ErrorState
                              err={error.resumelink}
                              errMsg={"Please enter a valid url"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <ReCAPTCHA
                sitekey="6LfjMsIoAAAAAHBlBpbjmSyiEZTPPFkjZERUfAxd"
                onChange={() => setIsVerified(false)}
                  // sitekey={`${process.env.REACT_APP_RECAPTCHA}`}
                  // onChange={() => setIsVerified(true)}
                />
                <div className="mt-5">
                  <Link to="/jobs">
                    <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                      Cancel
                    </button>
                  </Link>

                  <button
                    type="button"
                    disabled={!isFormValid()}
                    className={`bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3`}
                    onClick={handleSubmit}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 w-[340px]">
              <h1 className="text-3xl font-normal">Trending Jobs</h1>
              <div className="h-[70vh] flex flex-col gap-4 overflow-y-scroll">
                {openJobs
                  .filter((v) => v.priority === "hot")
                  .map((q) => (
                    
                    <div className="job__cards break-words p-4 shadow-openJobsShadow bg-white rounded-lg">
                      <h3 className="text-base font-bold py-2 ">
                        {q.jobtitle}
                      </h3>

                      <p className="py-1">
                        <span className="font-bold">Location: </span>
                        <span>{`${q?.location}`}</span>
                      </p>
                      <p className="py-1">
                        <span className="font-bold">Number of positon :</span>{" "}
                        <span>{q.positions}</span>
                      </p>
                      <p className="py-1">
                        <span className="font-bold"> Exp. :</span>{" "}
                        <span>
                          {q?.hasOwnProperty("totalExp")
                            ? `${q?.totalExp?.years}. ${q?.totalExp?.months}`
                            : "0.0"}
                          <small>(in Years)</small>
                        </span>
                      </p>
                      <p className="py-1">
                        <span className="font-bold">Mode of Work : </span>
                        <span>{q.worknature}</span>
                      </p>

                      <button
                        onClick={() => handleClick(q?._id)}
                        className="w-full inline-block bg-theme-color shadow-buttonShadow mt-2 disabled:opacity-50 py-2.5 rounded hover:bg-orange-400 text-white text-center"
                      >
                        Apply
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-theme-color text-white py-2 px-4 text-center">
        <p>Copyright © {new Date().getFullYear()} GetDevelopers</p>
      </footer>
    </>
  );
};

export default ApplyJob;
