import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import { Navigate, Outlet } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const ComponentsWithSideBar = () => {
  const [hideSidebar, setHideSidebar] = useState(false);

  const token = localStorage.getItem("userToken");
  // Toggle Sidebar //
  const toggleSidebar = () => {
    setHideSidebar(!hideSidebar);
  };
  if (token) {
    return (
      <>
        <div className="w-full flex">
          <Sidebar sidebar={hideSidebar} />
          <div className="w-1/6 md:w-3/6 lg:w-5/6">
            <FaBars
              className={`${
                window.location.pathname === "/" ||
                window.location.pathname === "/signup"
                //  ||window.location.pathname === "/job_apply"
                
                  ? "hidden"
                  : "block"
              } cursor-pointer md:block lg:hidden mt-[10px] ml-[10px]`}
              onClick={toggleSidebar}
            />
            <Outlet />
          </div>
        </div>
      </>
    );
  } else return <Navigate to="/" />;
};

export default ComponentsWithSideBar;
