import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import TermsCondition from "./components/TermsAndCondition/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import DataProvider, { DataContext } from "./context/Datastate";
import Dashboard from "./components/Dashboard/Dashboard";
import Sidebar from "./components/Sidebar/Sidebar";
import CreatePassword from "./components/CreatePassword/CreatePassword";
import CandidateList from "./components/Candidate/CandidateList";
import AddCandidate from "./components/Candidate/AddCandidate";
import UpdateCandidate from "./components/Candidate/UpdateCandidate";
import AddJob from "./components/Jobs/AddJob";
import JobList from "./components/Jobs/JobList";
import UpdateJob from "./components/Jobs/UpdateJob";
import ImportJob from "./components/Jobs/ImportJob";
import ImportCandidates from "./components/Candidate/ImportCandidates";
import Profile from "./components/Profile/Profile";
import AppliedJobsList from "./components/AppliedJobs/AppliedJobs";
import JobsList from "./components/JobsList/JobsList";
import ViewCandidateList from "./components/AppliedJobs/ViewCandidateList";
import ApplyJob from "./components/JobsList/ApplyJob";
import { useContext, useEffect, useState } from "react";
// import SignUp1 from "./components/SignUp/SignUp";
import { FaBars } from "react-icons/fa";
import ComponentsWithSideBar from "./components/indes";
import UploadVideo from "./components/JobsList/UploadVideo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-datepicker/dist/react-datepicker.css";

function App() {

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        theme="light"
      />
      <Router>
        <DataProvider>
          <div
            className={`flex ${
              window.location.pathname === "/jobs" ||
              window.location.pathname ===
                `/apply/${window.location.pathname.split("/").pop()}`
                ? "flex-col"
                : ""
            } w-full`}
          >
            {/* <Sidebar sidebar={hideSidebar} /> */}
            <div className="w-full">
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/signup" element={<SignUp />} />
                <Route exact path="/jobs" element={<JobsList />} />
                <Route exact path="/apply/:jobid" element={<ApplyJob />} />
                <Route exact path="/job_apply" element={<ApplyJob />} />
                <Route exact path="/upload_video/:candidateID" element={<UploadVideo />} />
                {/* <Route exact path="/signup" element={<SignUp />} /> */}
                {/* hello. */}
                <Route element={<ComponentsWithSideBar />}>
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route
                    exact
                    path="/candidate_list"
                    element={<CandidateList />}
                  />
                  <Route
                    exact
                    path="/add_candidate"
                    element={<AddCandidate />}
                  />
                  <Route
                    exact
                    path="/update_candidate/:id"
                    element={<UpdateCandidate />}
                  />
                  <Route
                    exact
                    path="/import-candidates"
                    element={<ImportCandidates />}
                  />
                  <Route exact path="/add_job" element={<AddJob />} />
                  <Route exact path="/job_list" element={<JobList />} />
                  <Route exact path="/update_job/:id" element={<UpdateJob />} />
                  <Route exact path="/import_job" element={<ImportJob />} />
                  <Route exact path="/profile" element={<Profile />} />
                  <Route
                    exact
                    path="/applied-jobs"
                    element={<AppliedJobsList />}
                  />
                  <Route
                    exact
                    path="/viewcandidate-list/:id"
                    element={<ViewCandidateList />}
                  />
                </Route>
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route
                  exact
                  path="/create-password"
                  element={<CreatePassword />}
                />
                <Route
                  exact
                  path="/reset-password"
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path="/terms-conditions"
                  element={<TermsCondition />}
                />
                <Route
                  exact
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                />
              </Routes>
            </div>
          </div>
        </DataProvider>
      </Router>
    </div>
  );
}
export default App;
