import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const CandidateModal = ({ item, setCandidateModal }) => {
    const handleClose = () => {
        setCandidateModal(false);
    }

    return (
        <div className="absolute w-full h-screen bg-[#00000090] z-[9]">

            <div className='w-2/3 p-12 absolute top-1/2 left-1/2 -translate-x-1/2 z-[10] -translate-y-1/2 rounded-md bg-white'>

                <AiOutlineCloseCircle className='close absolute right-5 top-5 text-[20px] text-red-600 z-[99]' onClick={() => handleClose()} />
                <div className="flex flex-col gap-3">
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Name</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.name}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Email</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.email}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Linkedin profile</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.linkedinprofile}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Resume link</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.resumelink}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Work Mode</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.workMode}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Primary technology</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item?.primarytechnology?.technologyname}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Year of experience </p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.hasOwnProperty("totalExp")
                            ? `${item?.totalExp?.years}. ${item?.totalExp?.months}`
                            : "0.0"}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Relevant experience</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.relevantexperience}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Phone</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.phonenumber}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Rate</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.rate}</p>
                    </div>
                    <div className="grid grid-cols-[1fr_30px_2fr] place-content-center place-items-start 1px solid #e7e7e7 border-b py-1">
                        <p className='font-bold text-[20px] text-theme-color'>Joining time</p>
                        <span>:</span>
                        <p className='text-black text-base'>{item.joiningtime}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CandidateModal
