import React, { useContext, useState } from "react"
import { DataContext } from "../../context/Datastate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import { motion } from "framer-motion"



// const Pagination=()=>{
//   const {currentPage,setCurrentPage,pageCount,publishPaginationApi}=useContext(DataContext)
  
//    console.log(pageCount,"page Count")
//   const arr=[...Array(pageCount).keys()]
//    console.log(arr.slice(0,5),"array1")
//    console.log(arr.slice(-4),"array1")

//   const handlePrevious=(currentPage)=>{
//     setCurrentPage((p)=>{
//       if(p===1) return p;
//       return p-1;
//     })
   
//   }
//   const handleNext=(currentPage)=>{
//     setCurrentPage((p)=>{
//       if(p===pageCount)return p;
//       return p+1;

//     })
    
//   }
//   console.log(currentPage,"current page")
//   return (
//     <>
//     <nav aria-label="Page navigation example">
//       <ul className="list-style-none flex">
//           <li  >
//               <a
//                   className="cursor-pointer relative block rounded-full bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300 dark:text-neutral-400 hover:bg-neutral-100" onClick={()=>{handlePrevious()}}>Previous</a>
//           </li>
//             {
//               arr.slice(0,5).map((item,index)=>{
//                 return (
//                   <>
//                    <li key="" className={`${currentPage===index+1 ? "bg-theme-color text-white" : "hover:bg-theme-light-color"} rounded-full`}>
                    
//               <a
//                   className={`   relative block rounded-full bg-transparent px-3 py-1.5 text-sm  transition-all duration-300   dark:text-white dark:hover:bg-neutral-700  `}
//                   href="#" onClick={()=>{
//                     publishPaginationApi(index+1,5)
//                     setCurrentPage(index+1)}
//                   } >{index+1} </a>
//           </li>
//                   </>
//                 )

//               })
//             }
                
//                 <li key="" >
//               <a
//                   className={`   relative block rounded-full bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300   dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"`}
//                   href="#" 
//                    >... </a>
//           </li>
                
//             {
//               arr.slice(-4).map((item)=>{
//                 return (
//                   <>
//                   <li key="" className={`${currentPage===item+1 ? "bg-theme-color text-white" : "hover:bg-theme-light-color"} rounded-full w-8 flex items-center justify-center`}>
                    
//               <a
//                   className={`   relative block rounded-full bg-transparent px-3 py-1.5 text-sm transition-all duration-300   dark:text-white dark:hover:bg-neutral-700  `}
//                   href="#" onClick={()=>{
//                     publishPaginationApi(item+1,5)
//                     setCurrentPage(item+1)}
//                   }
//                    >{item+1} </a>
//           </li>
//                   </>
//                 )
//               })
//             }    
             
         
          
//           <li>
//               <a
//                   className="relative block rounded-full bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
//                   href="#" onClick={()=>{handleNext()}}>Next</a>
//           </li>
//       </ul>
//   </nav>
//     </>
//   )
// }
const Pagination=({setFirstResultCount,limit,setSecondResultCount,api,id})=>{
  const {currentPage,setCurrentPage,pageCount,publishPaginationApi,totalCount}=useContext(DataContext)
  {console.log(currentPage,"currentPage")}
  const handlePageClick=(e)=>{
    if(id){
      api(e.selected+1,limit,id)
    }
    else{
      api(e.selected+1,limit)
    }
   
    setCurrentPage(e.selected+1)
    setFirstResultCount(e.selected*limit+1)
    if(e.selected+1===pageCount ||pageCount===1)
    {
      setSecondResultCount(totalCount)
    }
    else{
      setSecondResultCount((e.selected+1)*limit)
    }
    
  }
  const paginationVariants = {
    hidden: {
      opacity: 0,
      y: 200,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 5,
      },
    },
  };

  return (
    <motion.div
    variants={paginationVariants}
    initial="hidden"
    animate="visible"
  >
    <ReactPaginate
        breakLabel={<span className="mr-4">...</span>}
        nextLabel={
        
          <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md">
            <BsChevronRight />
          </span>
       
      }
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={
          
            <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md mr-4">
              <BsChevronLeft />
            </span>
         
        }
        forcePage={currentPage - 1}
        containerClassName="flex items-center justify-center  mb-4"
        pageClassName="block border- border-solid border-lightGray   w-6 h-6 text-sm flex items-center justify-center rounded-full mr-4" 
        activeClassName="bg-theme-color text-white"
      />
    
    </motion.div>
  )
}
export default Pagination;