import React, { useContext, useEffect ,useState} from "react";
import Icon from "../Assets/Icon.png";
import { FaUserAlt } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { BsBroadcast } from "react-icons/bs";
import dashboardbg from "../Assets/bg.png";
import { FiMic } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import profileImg from "../Assets/profile.avif";
import { DataContext } from "../../context/Datastate";
import { Link } from "react-router-dom";
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en'

const Dashboard = () => {
  const { AllCountApi, allCount,} = useContext(DataContext);
  const userInfo =JSON.parse(localStorage.getItem("userInfo"));
 
  // TimeAgo.addDefaultLocale(en)
  // const timeAgo = new TimeAgo('en-US')

  // const timeconversion=(date)=>{
  //   let nDate = String(date)
   
  //  return nDate.slice(11,16)
  // }

   useEffect(()=>{
    AllCountApi();
   }, [])
 
  return (
    <>
      <div className=" w-full flex">
        <div className="w-[100%] px-4 pt-5">
          <div className="bg-theme-white w-full">
            <div className="mx-3">
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-theme-black mt-0 text-xl font-semibold">
                    Dashboard
                  </h1>
                </div>
                <div className="font-semibold text-theme-black text-xl">Welcome <span>{userInfo?.vendorname}</span> </div>
              </div>
              <div className="grids grids-cols-4 gap-5 flex flex-row flex-wrap [&_a]:w-[45%] [&_a]:md:w-[30%] [&_a]:lg:w-[23%] pt-5 5xl:pt-[21px] 4xl:pt-[22px] 3xl:pt-[22px] 2xl:pt-[22px]  ">
                <Link to="/candidate_list">
                  <div className="flex bg-[#DBE6FE] 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[325px] 4xl:h-[135px] 3xl:w-[270px] 2xl:w-[250px] 2xl:h-[110px]  3xl:h-[100px] gap-[24px] items-center justify-center rounded-2xl">
                    <div className="flex justify-between w-full m-5">
                      <div>
                        <p className="font-semibold text-[12px] leading-4">
                          Total Candidate
                        </p>
                        <span className="font-semibold text-3xl">
                          {allCount?.totalCandidate}
                        </span>
                      </div>
                      <div className="mt-2">
                        <FaUserAlt />
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/job_list">
                  <div className="flex bg-[#FDE2DB] 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[325px] 4xl:h-[135px] 3xl:w-[270px] 2xl:w-[250px] 2xl:h-[110px] 3xl:h-[100px] gap-[24px] items-center justify-center rounded-2xl">
                    <div className="flex justify-between w-full m-5">
                      <div>
                        <p className="font-semibold text-[12px] leading-4">
                          Total Jobs
                        </p>
                        <span className="font-semibold text-3xl">
                          {allCount?.totalEnabledJob}
                        </span>
                      </div>
                      <div className="mt-2">
                        <MdDateRange size={18} />
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/applied-jobs">
                  <div className="flex bg-[#DBE6FE] 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[325px] 4xl:h-[135px] 3xl:w-[270px] 2xl:w-[250px] 2xl:h-[110px] 3xl:h-[100px] gap-[24px] items-center justify-center rounded-2xl">
                    <div className="flex justify-between w-full m-5">
                      <div>
                        <p className="font-semibold text-[12px] leading-4">
                         Applied Jobs
                        </p>
                        <span className="font-semibold text-3xl">
                          {allCount?.totalAppliedJobs}
                        </span>
                      </div>
                      <div className="mt-2">
                        <MdDateRange size={18} />
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="">
                  <div className="flex bg-[#FDE2DB] 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[325px] 4xl:h-[135px] 3xl:w-[270px] 2xl:w-[250px] 2xl:h-[110px] 3xl:h-[100px] gap-[24px] items-center justify-center rounded-2xl">
                    <div className="flex justify-between w-full m-5">
                      <div>
                        <p className="font-semibold text-[12px] leading-4">
                         Selected Candidates
                        </p>
                        <span className="font-semibold text-3xl">
                          {/* {allCount?.totalEnabledJob} */}0
                        </span>
                      </div>
                      <div className="mt-2">
                        <MdDateRange size={18} />
                      </div>
                    </div>
                  </div>
                </Link>
                {/* <Link to="/attendees">
                  <div className="flex bg-[#E1F5E8] 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[335px] 4xl:h-[135px] 3xl:w-[270px] 2xl:w-[250px] 2xl:h-[110px] 3xl:h-[100px] gap-[24px] items-center justify-center rounded-2xl">
                    <div className="flex justify-between w-full m-5">
                      <div>
                        <p className="font-semibold text-[12px] leading-4">
                          Attendee
                        </p>
                        <span className="font-semibold text-3xl">
                          9
                        </span>
                      </div>
                      <div className="mt-2">
                        <FiUsers size={17} />
                      </div>
                    </div>
                  </div>
                </Link> */}
              </div>
              
            </div>
          </div>
        </div>

        
      </div>
    </>
  );
};
export default Dashboard;
