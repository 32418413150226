import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../Assets/logo.png";
import coverImage from "../Assets/Vector5.png";
// import {FaRegEyeSlash} from "react-icons/fa";
import { DataContext } from "../../context/Datastate";
import { ToastContainer } from "react-toastify";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import ThankyouModal from "./ThankyouModal";
import ReCAPTCHA from "react-google-recaptcha";
import { calcLength } from "framer-motion";

const SignUp = () => {
  const navigate = useNavigate();

  const {
    signUpApi,
    setButtonLoader,
    resultSignUpState,
    // setResultSignUpState,
    ButtonLoader,
    thankyouModal,
    setThankyouModal,
  } = useContext(DataContext);
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const [phoneFormatTest1, setPhoneFormatTest1] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmpassword, setconfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 440) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleLogin = () => {
    setButtonLoader(true);
    signUpApi(state);
  };
  const [state, setState] = useState({
    vendorname: "",
    website: "",
    address: "",
    contactpersonname: "",
    contactpersonphone: "",
    alternatepersonname: "",
    alternatepersonphone: "",
    email: "",
    password: "",
    cnfpassword: "",
  });

  // console.log(state, "signupsatae")

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(state.email) && state.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
      setButtonLoader(false);
      // setResultSignUpState("");
    }
  };

  const validatePassword = () => {
    if (state.password === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
      // setResultSignUpState("");
    }
  };

  const validUrl = new RegExp(
    /^https?:\/\/(www\.)?([^\s/?\.#]+\.?)+(\/[^\s]*)?(\.[a-zA-Z]{2,})?$/
  );

  const validateUrl = () => {
    if (!validUrl.test(state.website) && state.website !== "") {
      setWebsiteError(true);
    } else {
      setWebsiteError(false);
    }
  };

  // const validatePhoneno1 = (phoneData1) => {
  //   var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   if (phoneData1.match(phoneno)) {
  //     return setPhoneFormatTest1(false);
  //   } else if (phoneData1 == "") {
  //     return setPhoneFormatTest1(false);
  //   } else {
  //     return setPhoneFormatTest1(true);
  //   }
  // };
  const validatePhoneno = (phoneData) => {
    var phoneno = /^(?!0{3})\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false);
    } else if (phoneData == "") {
      return setPhoneFormatTest(false);
    } else {
      return setPhoneFormatTest(true);
    }
  };

  const buttonDisabled =
    !websiteError &&
    !emailError &&
    !phoneFormatTest &&
    state.vendorname !== "" &&
    state.website !== "" &&
    state.address !== "" &&
    state.contactpersonname !== "" &&
    state.contactpersonphone !== "" &&
    state.email !== "" &&
    state.password !== "" &&
    state.password === state.cnfpassword &&
    isVerified;

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  },[isMobile]);

  console.log(isMobile, "==");
  return (
    <>
      <ToastContainer />
      {thankyouModal && <ThankyouModal />}
      <div
        className="w-full h-screen flex items-center justify-center flex-col bg-contain bg-top bg-no-repeat"
        style={{ backgroundImage: `url(${coverImage})` }}
      >
        <img src={logo} className=" w-[250px] pb-5 px-5" alt="logo"></img>
        <div className="w-[80%] lg:w-[50%] max-w-[750px] px-7 pb-4 bg-white overflow-y-scroll space-y-3 shadow hover:shadow-lg rounded-md pt-4">
          <div className="w-full xl:w-[90%] bg-whiterounded-md">
            <h1 className="text-center text-[20px] font-semibold">
              Sign Up for Get Developers
            </h1>
          </div>
          <hr />
          <div className="flex flex-col md:flex-row  md:space-x-10">
            <div className="w-full md:w-1/2 space-y-3">
              <div className="">
                <div className="pt-2">
                  <label
                    htmlFor="first-name"
                    className="text-sm px-1 font-semibold "
                  >
                    Vendor Name *
                  </label>
                  <input
                    type="text"
                    id="Vendor-name"
                    name="Vendorname"
                    placeholder="Enter your Vendor name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.vendorname}
                    onChange={(e) => {
                      setState({ ...state, vendorname: e.target.value });
                    }}
                  />
                </div>
                {/* <div>
                  <label
                    htmlFor="last-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Last name *
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    name="lastname"
                    placeholder="Enter your last name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.lastname}
                    onChange={(e) => {
                      setState({ ...state, lastname: e.target.value });
                    }}
                  />
                </div> */}

                <div>
                  <label
                    htmlFor="address"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Address *
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter your address"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.address}
                    onChange={(e) => {
                      setState({ ...state, address: e.target.value });
                    }}
                  />
                </div>

                <div>
                  <label
                    htmlFor="website"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Website * <small>(https://website.com)</small>
                  </label>
                  <input
                    id="website"
                    type="text"
                    name="website"
                    placeholder="https://website.com"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    // value={state.Website}
                    onChange={(e) => {
                      setState({ ...state, website: e.target.value });
                    }}
                    onKeyUp={(e) => validateUrl(e.target.value)}
                  />
                  {websiteError && (
                    <span className="text-left text-red-600 text-sm">
                      Please enter a valid url
                    </span>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="person-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Contact Person Name *
                  </label>
                  <input
                    id="person-name"
                    type="text"
                    name="personname"
                    placeholder="Enter your contact person name"
                    className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.contactpersonname}
                    onChange={(e) => {
                      setState({
                        ...state,
                        contactpersonname: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 pt-2 ml-0">
              <div className="pb-3">
                <label
                  htmlFor="phone"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Contact Person Phone *
                </label>
                <input
                  type="text"
                  maxLength={10}
                  pattern="[0-9]{10}"
                  id="phone"
                  name="phone"
                  placeholder="Enter your contact person phone"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                  value={state.contactpersonphone}
                  onChange={(e) => {
                    setState({
                      ...state,
                      contactpersonphone: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePhoneno(state.contactpersonphone)}
                />
                {phoneFormatTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter your email"
                  className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 ${
                    emailError ? "" : "mb-4"
                  } `}
                  value={state.email}
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
                {emailError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid Email
                  </span>
                )}
              </div>
              <div className="pb-2">
                <label
                  htmlFor="password"
                  className="text-sm px-1 font-semibold relative block"
                >
                  Password *
                  <input
                    type={password === true ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    name="password"
                    placeholder="Enter your password"
                    className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.password}
                    onChange={(e) => {
                      setState({ ...state, password: e.target.value });
                    }}
                    onKeyUp={() => validatePassword()}
                  />
                  <span
                    className="absolute top-7 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setPassword(!password)}
                  >
                    {password === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>
                {passwordError && (
                  <small className="text-left text-red-600">
                    Enter Password
                  </small>
                )}
                <small className="text-left text-red-600">
                  {resultSignUpState}
                </small>
              </div>
              <div className="pt-2">
                <label
                  htmlFor="password"
                  className="text-sm px-1 font-semibold relative block"
                >
                  Confirm Password *
                  <input
                    type={confirmpassword === true ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    name="password"
                    placeholder="Confirm password"
                    className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.cnfpassword}
                    onChange={(e) => {
                      setState({ ...state, cnfpassword: e.target.value });
                    }}
                    onKeyUp={() => validatePassword()}
                  />
                  <span
                    className="absolute top-7 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setconfirmPassword(!confirmpassword)}
                  >
                    {password === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>

                {state.password !== state.cnfpassword && (
                  <small className="text-left text-red-600">
                    Passwords do not match
                  </small>
                )}

                <small className="text-left text-red-600">
                  {resultSignUpState}
                </small>
              </div>
            </div>
          </div>
          <Link
            to="/"
            className="text-theme-color text-[13px] font-medium underline"
            rel="noreferrer"
          >
            Back to Login
          </Link>
          <ReCAPTCHA
            sitekey={`${process.env.REACT_APP_RECAPTCHA}`}
            onChange={() => setIsVerified(true)}
            // size={isMobile ? "normal" : "compact"}
            size="normal"
          />
          <div className="flex justify-center mt-6 text-center">
            <button
              onClick={() => handleLogin()}
              type="button"
              disabled={!buttonDisabled}
              className="bg-theme-color  text-center disabled:opacity-40 flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 p-[.5rem_5rem] rounded-lg  font-bold cursor-pointer"
            >
              <span
                className={` ${
                  ButtonLoader
                    ? "border-t-transparent inline-block border-2 border-white animate-spin rounded-full w-5 h-5"
                    : ""
                } `}
              ></span>
              SignUp
            </button>
            {/* <button
                  disabled={buttonDisabled}
                  onClick={() => handleLogin()}
                  type="button" 
                  className="bg-theme-color text-white p-2 w-80 rounded-lg  font-bold disabled:opacity-40">
                  Login
                </button> */}
          </div>
          {/* <div className="text-center">
                <p className="text-xl 2xl:text-sm ">Don't have an account? <Link to="/signup" className="text-theme-color font-medium underline" rel="noreferrer">Register</Link></p>
              </div> */}
        </div>
        <small className=" mt-4">
          © {new Date().getFullYear()} Get Developers SignUp
        </small>
      </div>
    </>
  );
};

export default SignUp;
