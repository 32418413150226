import React, { useContext, useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { DataContext } from "../../context/Datastate";
import { Link } from "react-router-dom";
import nodata from "../Assets/nodata.png";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ViewJDModal = ({ apiFunction, jd }) => {

  const { job, setJobId, viewJDModal, setViewJDModal } =
    useContext(DataContext);

  return (
    <>
      <div className="absolute w-full h-screen bg-[#00000090] z-[5] ">
        <div className="w-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 z-[6] -translate-y-1/2 rounded-md bg-white px-4 ">
          <AiOutlineCloseCircle
            className="close cursor-pointer absolute right-5 top-5 text-[20px] text-red-600 z-[99]"
            onClick={apiFunction}
          />
          <div className="pt-5 flex justify-between items-start">
            <h1 className="text-xl text-[#202223] font-semibold">
             Job Description
            </h1>
          </div>
          {/*--------- table list--------- */}
          {/* {globalLoader ? (
            <GlobalLoader />
          ) : ( */}
          <div className="h-[65vh] overflow-y-scroll relative pt-5 ">
            {jd ? (
              <>
                <ReactQuill theme="snow"
                  className="w-full jd_textarea h-full px-4 py-2 resize-none"
                  readOnly
                  value={jd}
                />
                
                
                {/* <table className="w-full table-auto bg-white border-collapse shadow-md rounded-lg  "> */}
                {/* <thead className="cursor-pointer sticky top-0 z-[4]">
                  <tr className=" text-left bg-theme-light text-theme-color text-xs font-normal  ">
                    
                    <th className=" px-4 py-3 flex items-center">
                      <div className="flex items-center gap-4">
                        <p>Job Description</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                  
                    <tr className="border-t text-left text-[16px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                     
                    </tr>
                
                </tbody> */}
                {/* </table> */}
              </>
            ) : (
              <div className="flex w-full bg-white">
                <div className="w-full p-8  bg-white overflow-y-scroll h-[45vh] ">
                  <div className="border border-dashed border-[#BABFC3] rounded flex flex-col justify-center items-center h-[35vh]">
                    <img src={nodata} alt="nodata" />
                    <span classname="text-[#6D7175] text-sm font-normal">
                      No Data Available
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewJDModal;
