import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import { ToastContainer } from "react-toastify";

const AddCandidate = () => {
  const {
    AddCandidateApi,
    getCandidateById,
    candidate,
    disableSave,
    getTechnologyApi,
    technologyList,
    setTechnologyList,
    getStatusListApi,
    statusList,
    setStatusList,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);
  const [aadharError, setAadharError] = useState("");
  const [resumeError, setResumeError] = useState("")
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [urlError, setUrlError] = useState(null);
  const [linkedinError, setLinkedinError] = useState(null);
  const [aadharFileName, setAadharFileName] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [panNumberTest, setPanNumberTest] = useState(false);
  const [pfNumberTest, setPfNumberTest] = useState(false);
  // const [resumeError, setResumeError] = useState("")
  // const [resumeFileName, setResumeFileName] = useState("");

  // const [websiteError, setWebsiteError] = useState(false);

  const [state, setState] = useState({
    name: "",
    linkedinprofile: "",
    resumelink: "",
    primarytechnology: "",
    phonenumber: "",
    rate: "",
    joiningtime: "Immediate",
    totalExp: {
      years: "",
      months: "",
    },
    relevantExp: {
      years: "",
      months: "",
    },
    status: "64f997e6d3d3c1cadc894a75",
    pannumber: "",
    aadhar: "",
    pf: "",
    resume: ""
  });
  function validatePAN(panVal) {
    var regpan = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    if (regpan.test(panVal)) {
      return setPanNumberTest(false);
    } else if (panVal == "") {
      return setPanNumberTest(false);
    } else {
      return setPanNumberTest(true);
    }
  }
  function validatePF(pfVal) {
    var regpan =
      /^[A-Z]{2}[\s\/]?[A-Z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7}$/;
    if (regpan.test(pfVal)) {
      return setPfNumberTest(false);
    } else if (pfVal == "") {
      return setPfNumberTest(false);
    } else {
      return setPfNumberTest(true);
    }
  }

  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false);
    } else if (phoneData == "") {
      return setPhoneFormatTest(false);
    } else {
      return setPhoneFormatTest(true);
    }
  };
  
  const handleAadharFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setAadharError("Please select an Aadhar file.");
      return;
    }
    const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      setAadharError("Invalid file type. Please select a PDF, JPG, JPEG, or PNG file.");
      setAadharFileName("")
      setState({ ...state, aadhar: "" })
      return;
    }

    if (file.size > 1024 * 1024) {
      setAadharError("File size should be less than 1 MB.");
      setAadharFileName("")
      setState({ ...state, aadhar: "" })
      return;
    }

    setAadharError("");
    setState({ ...state, aadhar: file })
    setAadharFileName(file.name);
  };

  const handleResumeFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setResumeError("Please select an Resume file.");
      return;
    }
    const allowedTypes = ["application/pdf", "application/doc","application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
    if (!allowedTypes.includes(file.type)) {
      setResumeError("Invalid file type. Please select a PDF, DOC file.");
      setResumeFileName("")
      setState({ ...state, resume: "" })
      return;
    }

    if (file.size > 1024 * 1024) {
      setResumeError("File size should be less than 1 MB.");
      setResumeFileName("")
      setState({ ...state, resume: "" })
      return;
    }

    setResumeError("");
    setState({ ...state, resume: file })
    setResumeFileName(file.name);
  };

  const validateUrl = (link, fieldname) => {
    const validUrl =
    /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;
    if (!validUrl.test(link) && link !== "") {
      setUrlError(fieldname);
    } else {
      setUrlError(null);
    }
  };
  
  const linkedinUrl = (link, fieldname) => {
    const linkUrl = /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;
    if (!linkUrl.test(link) && link !== "") {
      setLinkedinError(fieldname);
    } else {
      setLinkedinError(null);
    }
  };
  
  const buttonDisabled =
  urlError ||
  resumeError ||
  linkedinError ||
  phoneFormatTest ||
    state.name === "" ||
    state.linkedinprofile === "" ||
    state.resumelink === "" ||
    state.phonenumber === "" ||
    state.primarytechnology === "" ||
    state.rate === "" ||
    state.joiningtime === "" ||
    state.totalExp.years === "" ||
    state.totalExp.months === "" ||
    state.status === "" ||
    state.resume === "" ||
    state.relevantExp.years === "" ||
    state.relevantExp.months === "";

  const handleSubmit = () => {
    if (
      state.linkedinprofile === "https://" ||
      linkedinError === "linkedinprofile"
    ) {
      state.linkedinprofile = "";
    }

    if (state.resumelink === "https://" || urlError === "resumelink") {
      state.resumelink = "";
    }
    if (!disableSave) AddCandidateApi(state);
  };


  useEffect(() => {
    getTechnologyApi();
    getStatusListApi();
  }, []);
  //first letter should be capitel functionality
  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  return (
    <>
      <ToastContainer />
      <div className="flex w-full bg-white">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/candidate_list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1  ">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                Candidate info{" "}
              </h1>
            </div>
          </div>
          <div className="flex space-x-10">
            <div className="w-1/3 space-y-3 ">
              <h2 className="font-semibold text-lg">Candidate details</h2>
              <hr />
              <div className="">
                <div>
                  <label htmlFor="name" className="text-sm px-1 font-semibold ">
                    Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your candidate name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.name}
                    // onChange={(e) => {
                    //   setState({ ...state, name:toTitleCase(e.target.value) });
                    // }}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^a-zA-Z\s]/g, ''); 
                      setState({ ...state, name: toTitleCase(filteredInput) });
                    }} 
                  />
                </div>
                {/* <div>
                  <label
                    htmlFor="last-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Last name *
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    name="lastname"
                    placeholder="Enter your last name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.lastname}
                    onChange={(e) => {
                      setState({ ...state, lastname: e.target.value });
                    }}
                  />
                </div> */}

<div>
                  <label
                    htmlFor="linkedinprofile"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Linkedin Profile * <small className="text-gray-500">(https://linkedinprofile.com)</small>
                  </label>
                  {/* <div className="flex ">
                    <div className="border border-r-0 py-1 pl-2 pr-1 mb-3 leading-[27px] border-gray-300 shadow-sm rounded-tl-md rounded-bl-md placholder:text-slate-400 text-sm h-9">
                      <span>https://</span>
                    </div> */}
                  <input
                    type="text"
                    name="linkedinprofile"
                    placeholder="Enter your linkedin profile"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.linkedinprofile}
                    onKeyUp={()=> linkedinUrl(state.linkedinprofile, "linkedinprofile")}
                    onChange={(e) => {
                      setState({ ...state, linkedinprofile: e.target.value });
                    }}
                  />
                  {/* </div> */}
                  {linkedinError === "linkedinprofile" ? <span className="text-red-600 text-xs">Please enter valid input</span> : null}
                </div>
                <div>
                  <label
                    htmlFor="status"
                    className="text-sm px-1 font-semibold pb-1"
                  >
                    Status *
                  </label>
                 
                  <select
                    id="status"
                    type="text"
                    name="status"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.status}
                    onChange={(e) => {
                      setState({ ...state, status: e.target.value });
                    }}>

                    {statusList.filter((i) => i.statusname !== "Rejected" && i.statusname !== "Profile submitted to client")?.map((v) => {
                      if (v?.isActive) {
                        return (
                          <option
                            selected={state.status}
                            key={v?._id}
                            value={v?._id}
                          >
                            {v?.statusname}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="rate"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Rate *
                  </label>
                  <input
                    id="rate"
                    type="text"
                    name="rate"
                    placeholder="Enter your rate"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.rate}
                    onChange={(e) => {
                      setState({ ...state, rate: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="pf"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    PF Number
                  </label>
                  <input
                    type="text"
                    name="pf"
                    maxLength={22}
                    placeholder="Enter your pf"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.pf}
                    onChange={(e) => {
                      setState({ ...state, pf: e.target.value });
                    }}
                    onKeyUp={() => validatePF(state.pf)}
                  />
                  {pfNumberTest ? (
                    <span className="text-red-600 text-sm">
                      Please enter a valid 12 digit Number.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/3 pt-8 ">
            <label
                htmlFor="totalExp"
                className="pt-2 text-sm px-1 font-semibold pb-1"
              >
                Total Year of Experience *
              </label>
              <div className="flex items-baseline gap-x-2  ">
                <div>
                  <label
                    htmlFor="year"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Year *
                  </label>
                  <select
                    name="totalExp"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.totalExp.years}
                    onChange={(e) =>
                      setState({
                        ...state,
                        totalExp: {
                          ...state.totalExp,
                          years: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select Years</option>
                    {generateOptions(0, 30, "year")}
                  </select>
                </div>
                <div className="">
                  <label
                    htmlFor="months"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Months *
                  </label>
                  <select
                    name="monthsofexperience"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.totalExp.months}
                    onChange={(e) =>
                      setState({
                        ...state,
                        totalExp: {
                          ...state.totalExp,
                          months: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select Months</option>
                    {generateOptions(0, 12, "months")}
                  </select>
                </div>
              </div>

              <div className="pb-2">
                <label
                  htmlFor="phone"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Phone *
                </label>
                <input
                  type="text"
                  maxlength={10}
                  pattern="[0-9]{10}"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                  value={state.phonenumber}
                  onChange={(e) => {
                    setState({
                      ...state,
                      phonenumber: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePhoneno(state.phonenumber)}
                />
                {phoneFormatTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <label
                  htmlFor="primarytechnology"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Primary Technology *
                </label>
                <select
                  id="primarytechnology"
                  type="text"
                  name="primarytechnology"
                  placeholder="Enter your primary technology"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state?.primarytechnology?._id}
                  onChange={(e) => {
                    setState({ ...state, primarytechnology: e.target.value });
                  }}
                >
                  <option value="" selected disabled hidden>
                    Choose primary technology
                  </option>
                  {technologyList?.map((v) => {
                    if (v?.isActive) {
                      return (
                        <option
                          key={v?._id}
                          value={v?._id}
                        >
                          {v?.technologyname}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </div>
              <div className="pb-2">
                <label
                  htmlFor="pannumber"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Pan Number
                </label>
                <input
                  type="text"
                  maxLength={10}
                  pattern=""
                  id=""
                  name="pannumber"
                  placeholder="Enter your pan number"
                  className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.pannumber}
                  onChange={(e) => {
                    setState({ ...state, pannumber: e.target.value });
                  }}
                  onKeyUp={() => validatePAN(state.pannumber)}
                />
                {panNumberTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Pan Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="pt-2">
                <label
                  htmlFor="upload resume" 
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Upload Resume *<small className="font-normal">(allowed .doc,.docx, .pdf)</small>
                </label>
                <input
                  type="file"
                  name="upload resume"
                  placeholder="upload resume"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  onChange={handleResumeFileChange}
                />
                {resumeError && <span className="text-red-600 text-xs">{resumeError}</span>}
                {resumeFileName && (
                  <span className="text-green-600 text-xs">Selected file: {resumeFileName}</span>
                )}
              </div>
            </div>

            <div className="w-1/3 pt-7">
            <label
                          htmlFor="relevantExp"
                          className="pt-2 text-sm px-1 font-semibold pb-1"
                        >
                          Relevant Experience *
                        </label>
                      <div className="flex items-baseline gap-x-2 ">
                        <div>
                        <label
                          htmlFor="year"
                          className="pt-2 text-sm px-1 font-semibold pb-1"
                        >
                          Year *
                        </label>
                          <select
                            name="relevantExp"
                            className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                            value={state.relevantExp.years}
                            onChange={(e) =>
                              setState({
                                ...state,
                                relevantExp: {
                                  ...state.relevantExp,
                                  years: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Years</option>
                            {generateOptions(0, 30, "year")}
                          </select>
                        </div>
                        <div className="">
                        <label
                          htmlFor="month"
                          className="pt-2 text-sm px-1 font-semibold pb-1"
                        >
                          Months *
                        </label>
                          <select
                            name="relevantExp"
                            className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                            value={state.relevantExp.months}
                            onChange={(e) =>
                              setState({
                                ...state,
                                relevantExp: {
                                  ...state.relevantExp,
                                  months: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Months</option>
                            {generateOptions(0, 12, "months")}
                          </select>
                        </div>
                      </div>
              <div>
                <label
                  htmlFor="resumelink"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Resume Link * <small className="text-gray-500">(https://resumelink.com)</small>
                </label>
                {/* <div className="flex ">
                  <div className="border border-r-0 py-1 pl-2 pr-1 mb-3 leading-[27px] border-gray-300 shadow-sm rounded-tl-md rounded-bl-md placholder:text-slate-400 text-sm h-9">
                    <span>https://</span>
                  </div> */}
                <input
                  id="person-name"
                  type="text"
                  name="resumelink"
                  placeholder="Enter your resume link"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.resumelink}
                  onKeyUp={()=> validateUrl(state?.resumelink, "resumelink")}
                  onChange={(e) => {
                    setState({ ...state, resumelink: e.target.value });
                  }}
                />
                {/* </div> */}
                 {urlError === "resumelink" ? <span className="text-red-600 text-xs">Please enter valid input</span> : null}
              </div>
              <div className="">
                <label
                  htmlFor="joiningtime"
                  className="text-sm px-1 font-semibold pb-1"
                >
                  Joining Time *
                </label>
                <select
                  id="joiningtime"
                  type="text"
                  name="joiningtime"
                  placeholder="Enter your joining time"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.joiningtime}
                  onChange={(e) => {
                    setState({ ...state, joiningtime: e.target.value });
                  }}>
                  <option selected value="Immediate">
                    Immediate
                  </option>
                  <option value="15 days">15 days</option>
                  <option value="30 days">30 days</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="aadhar"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Aadhar <small className="font-normal">(allowed .jpg, .png and .pdf)</small>
                </label>
                <input
                  type="file"
                  name="aadhar"
                  placeholder="Enter your aadhar"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  // value={state.aadhar}
                  // onChange={(e) => {
                  //   setState({ ...state, aadhar: e.target.value });
                  // }}
                  onChange={handleAadharFileChange}
                />
                {aadharError && <span className="text-red-600 text-xs">{aadharError}</span>}
                {aadharFileName && (
                  <span className="text-green-600 text-xs">Selected file: {aadharFileName}</span>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <Link to="/candidate_list">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              disabled={buttonDisabled}
              className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCandidate;
