import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { FaArrowLeft ,FaRegEye, FaRegEyeSlash, FaUser} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import { ToastContainer } from "react-toastify";
import profileLogo from "../Assets/profile.jpg";

const Profile = () => {

    const {profile} = useContext(DataContext);

 return (
   <>
    <div className="flex px-8 w-full flex-col pt-8">
        <div className="w-[90px] flex gap-8 h-[90px] rounded-full mb-4">
         {!profileLogo ? <img className="w-full h-full object-cover rounded-full" src={profileLogo} alt=""  /> : <FaUser className="w-full h-full p-2 bg-[#b3b3b3] text-white rounded-full"/>}</div>
          <div className="flex w-full gap-8">
              <div className="w-1/2 space-y-3">
                <h2 className="font-semibold text-lg pb-4">My Profile</h2>
                <div className="">
                  <div>
                    <label
                      htmlFor="name"
                      className="text-sm px-1 font-semibold"
                    >
                      Name *
                    </label>
                    <input
                    readonly 
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your Candidate name"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={profile.vendorname}
              
                    />
                  </div>
                  {/* <div>
                    <label
                      htmlFor="last-name"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Last name *
                    </label>
                    <input
                      id="last-name"
                      type="text"
                      name="lastname"
                      placeholder="Enter your last name"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={state.lastname}
                      onChange={(e) => {
                        setState({ ...state, lastname: e.target.value });
                      }}
                    />
                  </div> */}
                  <div>
                    <label
                      htmlFor="linkedinprofile"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Email
                    </label>
                    <input
                    readonly
                      type="text"
                      name="linkedinprofile"
                      placeholder="Enter your linkedin profile"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={profile.email}
              
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="resumelink"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                     Address
                    </label>
                    <input
                    readonly
                      id="person-name"
                      type="text"
                      name="resumelink"
                      placeholder="Enter your resume link"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={profile.address}
              
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="primarytechnology"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                     Alternate Phone
                    </label>
                    <input
                    readonly
                      id="alternatephone"
                      type="text"
                      name="alternatephone"
                      placeholder="Enter your primarytechnology"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={profile.alternatepersonphone}
              
                    >
              
                    </input>
                  </div>
                 
              
                </div>
              </div>
              <div className="w-1/2 pt-14">
              <div>
                    <label
                      htmlFor="yearofexperience"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Alternate Person Name
                    </label>
                    <input
                    readonly
                      type="text"
                      name="yearofexperience"
                      placeholder="Enter your year of experience"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={profile.alternatepersonname}
              
                    />
                  </div>
                <div>
                    <label
                      htmlFor="relevantexperience"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Contact Person
                    </label>
                    <input
                    readonly
                      type="text"
                      name="relevantexperience"
                      placeholder="Enter your relevant experience"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={profile.contactpersonname}
              
                    />
                  </div>
                  <div className="pb-2">
                    <label
                      htmlFor="phone"
                      className=" text-sm px-1 font-semibold pb-1"
                    >
                    Contact Person Phone
                    </label>
                    <input
                    readonly
                      type="text"
                      maxlength={10}
                      pattern="[0-9]{10}"
                      id="phone"
                      name="phone"
                      placeholder="Enter your phone number"
                      className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                      value={profile.contactpersonphone}
              
                    />
              
                  </div>
                <div>
                  <label
                    htmlFor="rate"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Website
                  </label>
                  <input
                  readonly
                    id="website"
                    type="text"
                    name="website"
                    placeholder="Enter your rate"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={profile.website}
              
                  />
                </div>
              
              
              </div>
          </div>
        </div>
   </>
  )
}

export default Profile
