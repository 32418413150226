import React, { useRef, useState } from 'react';
// import { FaPlay, FaStop } from "react-icons/fa";

const VideoRecorder = ({ setState, fileKey, onUpload, loading }) => {
  const videoRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);

  const startRecording = async () => {
    setRecordedVideoUrl(null)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videoRef.current.srcObject = stream;
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
        const file = new File([blob], 'recorded-video.webm', { type: 'video/webm' });
        setState((prev) => ({ ...prev, [fileKey]: file }))
        const url = URL.createObjectURL(blob);
        setRecordedVideoUrl(url);
      };

      recordedChunksRef.current = [];
      mediaRecorder.start();
      setIsRecording(true);

      setTimeout(() => {
        mediaRecorder.stop();
        setIsRecording(false);
      }, 21000); // 20 seconds

    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  };

  const stopRecording = () => {
    if (videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
  };

  // const downloadVideo = () => {
  //   if (recordedChunksRef.current.length > 0) {
  //     const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
  //     const url = URL.createObjectURL(blob);

  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'recorded-video.webm';
  //     a.click();
  //   }
  // };


  return (
    <>
      <div className='bg-black relative h-full w-full'>
        {!recordedVideoUrl && <video ref={videoRef} className='w-full h-full' muted autoPlay playsInline disablePictureInPicture style={{ transform: 'scaleX(-1)' }}></video>}
        {!!recordedVideoUrl && <video controls className='w-full h-full' src={recordedVideoUrl} playsInline disablePictureInPicture></video>}
      </div>

      <div className='flex justify-evenly mt-3'>
        {isRecording ? (
          <button className="bg-theme-color disabled:opacity-40 px-8 text-sm py-2 font-bold rounded-lg hover:bg-orange-400 text-white ml-3" onClick={stopRecording}>Stop</button>
        ) : (
          <button className="bg-theme-color disabled:opacity-40 px-8 text-sm py-2 font-bold rounded-lg hover:bg-orange-400 text-white ml-3" onClick={startRecording} disabled={!!loading}>Start</button>
        )}
        {!!onUpload &&
          <button
            onClick={onUpload}
            disabled={!recordedVideoUrl || loading}
            className="bg-theme-color text-center disabled:opacity-40 flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 py-2 px-8 rounded-lg font-bold cursor-pointer ml-3"
          >
            <span
              className={` ${!!loading && "border-t-transparent border-2 border-white animate-spin rounded-full w-5 h-5 mr-2"} `}
            ></span>
            Upload
          </button>}
        {/* {!!onUpload &&
          <button
            className='bg-theme-color disabled:opacity-40 px-8 text-sm transition ease-in-out delay-150 duration-300 rounded hover:bg-orange-400 text-white ml-3'
            onClick={onUpload}
            disabled={!recordedVideoUrl || loading}
          >{
              <span
                className={!!loading && "border-t-transparent border-2 border-white animate-spin rounded-full w-2 h-2 mr-2"}
              ></span>} Upload</button>} */}
        {/* <button className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3" onClick={startRecording}>Retake</button> */}
      </div>
    </>
  );
};

export default VideoRecorder;
