import React, { useContext, useEffect } from "react";
import { FaThLarge, FaBlackTie, FaUser, FaCrown} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Assets/logo.png";
import { DataContext } from "../../context/Datastate";
import LogoutModal from "../LogoutModal/LogoutModal";
import { FiLogOut,FiUser } from "react-icons/fi";

function Sidebar(props) {
  const {
    logout,
    setLogout,
    checkLogin,
    setCheckLogin,
    addPublishApi,
    allCount,
    disableSave,
  } = useContext(DataContext);
  const {sidebar} = props;
  console.log(sidebar,"+++")
  // console.log(props,"+++")
  const location = useLocation();
  useEffect(() => {}, [location]);
  // console.log(allCount,"allCount")

  return (
    <>
      {logout ? <LogoutModal /> : ""}
      {checkLogin ? (
        <div className={`${sidebar ? "w-0" : "w-5/6 md:w-3/6"} ease-in-out duration-300 lg:w-1/6 max-w-[200px] shrink-0 bg-white border-r-2 `}>
          <div className="px-4">
            <img className="w-[180px] py-5 " src={Logo} alt="" />
          </div>
          {/* <div className="flex items-center justify-center  gap-4 pl- pr-5 pt-5 pb-6">
            <img className="w-[25px] " src={Sidebar1} alt="sidebar"></img>
            <h2 className="text-black font-bold font-poppins lg:text-lg 2xl:text-2xl text-xl ">Event Web</h2>
            <span ><FaArrowCircleLeft/></span>
          </div> */}
          <ul className=" flex flex-col gap-[4px] h-[85vh] overflow-y-scroll scrollbar-none px-4">
            <li
              className={`flex items-center hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl ${
                location.pathname === "/dashboard"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              } `}
            >
              <Link className="w-full  p-3" to="/dashboard">
                <div className="flex items-center text-sm">
                  <FaThLarge />
                  <h2 className=" text-md font-normal pl-2 ">Dashboard</h2>
                </div>
              </Link>
            </li>

            <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/candidate_list" ||
                location.pathname ==
                  "/update_candidate/" + location.pathname.split("/").pop() ||
                location.pathname === "/candidate_detail" ||
                //  location.pathname==="/import_candidate" ||
                location.pathname === "/add_candidate"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to="/candidate_list">
                <div className="flex items-center  text-sm">
                  <FiUser
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${
                      location.pathname === "/candidate_list" ||
                      location.pathname ==
                        "/update_candidate/" +
                          location.pathname.split("/").pop() ||
                      location.pathname === "/candidate_detail" ||
                      // ||  location.pathname==="/import_candidate"
                      location.pathname === "/add_candidate"
                        ? "bg-theme-color text-white active group-hover:text-white"
                        : "text-black"
                    } `}
                  />
                  <h2 className=" text-md font-normal pl-2 flex">
                    <span className="">
                    Candidate
                    </span>
                    <span>({allCount?.totalCandidate})</span>
                  </h2>
                </div>
              </Link>
            </li>
            <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/job_list" ||
                location.pathname ==
                  "/update_job/" + location.pathname.split("/").pop() ||
                location.pathname === "/job_detail" ||
                location.pathname === "/import_job" ||
                location.pathname === "/add_job"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to="/job_list">
                <div className="flex items-center  text-sm">
                  <FaBlackTie
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${
                      location.pathname === "/job_list" ||
                      location.pathname ==
                        "/update_job/" + location.pathname.split("/").pop() ||
                      location.pathname === "/job_detail" ||
                      location.pathname === "/import_job" ||
                      location.pathname === "/add_job"
                        ? "theme-color text-white active group-hover:text-white"
                        : "text-black"
                    } `}
                  />
                  <h2 className=" text-md font-normal pl-2 flex">
                    <span className="">
                    Jobs
                    </span>
                    <span>({allCount?.totalEnabledJob})</span>
                  </h2>
                </div>
              </Link>
            </li>

            <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/applied-jobs"
                  || location.pathname ==
                      "/viewcandidate-list/" + location.pathname.split("/").pop() ?
                    // location.pathname === "/job_detail" ||
                    // location.pathname === "/import_job" ||
                    // location.pathname === "/add_job"
                    "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to="/applied-jobs">
                <div className="flex items-center  text-sm">
                  <FaCrown
                    className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${
                      location.pathname === "/applied-jobs"
                        || location.pathname ===
                            "/viewcandidate-list/" + location.pathname.split("/").pop() ?
                          // location.pathname === "/job_detail" ||
                          // location.pathname === "/import_job" ||
                          // location.pathname === "/add_job"
                          "theme-color text-white active group-hover:text-white"
                        : "text-black"
                    } `}
                  />
                  <h2 className=" text-md font-normal pl-2 flex ">
                    <span className="">Applied Jobs </span><span>({allCount?.totalAppliedJobs})</span>
                  </h2>
                </div>
              </Link>
            </li>

            <div className="flex flex-col mt-auto">
              <li
                className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === ""
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <div className="w-full p-3" to="">
                  <div className="flex items-center w-full  text-sm">
                    <FiUser className="text-xl" />
                    <a href="https://docs.google.com/document/d/e/2PACX-1vRDvgRKtcJWXC2vSGubOcXS7GDZPpNidVfKDa5B9i1INO0BEJ_MK2cdKG7MIBR0fQ/pub" target="_blank" className=" text-md text- px-5 ">User Manual</a>
                  </div>
                </div>
              </li>

              <li
                className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/profile"
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to="/profile">
                  <div className="flex items-center w-full  text-sm">
                    <FaUser className="text-xl" />
                    <h2 className=" text-md text- px-5">Profile</h2>
                  </div>
                </Link>
              </li>

              <li
                className={` mt-auto flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/logout"
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link
                  className="w-full p-3"
                  onClick={() => {
                    setLogout(true);
                  }}
                >
                  <div className="flex items-center w-full  text-sm">
                    <FiLogOut className="text-xl" />
                    <h2 className=" text-md text- px-5">Logout</h2>
                  </div>
                </Link>
              </li>
            </div>
            {/* <li className="text-center py-2">
              <button className="text-sm py-3 text-white px-14 rounded-3xl bg-theme-color font-normal font-theme-font" onClick={()=>{ if (!disableSave) addPublishApi(true)}}>Publish</button>
            </li> */}
          </ul>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Sidebar;
