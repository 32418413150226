import React, { createContext, useState, useEffect } from "react";
import { FaIgloo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  // const BASE_URL = "https://getdeveloper.in/api/";
  const BASE_URL = "https://api.getdeveloper.in/";
  const navigate = useNavigate();
  const [resultLoginState, setResultLoginState] = useState("");
  const [checkLogin, setCheckLogin] = useState(false);
  const [logout, setLogout] = useState(false);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [globalLoader, setGlobalLoader] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [allCandidate, setAllCandidate] = useState([]);
  const [job, setJob] = useState([]);
  const [id, setId] = useState("");
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [deleteBtnId, setDeleteBtnId] = useState("");
  const [allCount, setAllCount] = useState({});
  const [disableSave, setDisableSave] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [technologyList, setTechnologyList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [profile, setProfile] = useState({});
  const [applyJobModal, setApplyJobModal] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [viewJDModal, setViewJDModal] = useState(false);
  const [thankyouModal, setThankyouModal] = useState(false);
  const [openJobs, setOpenJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewcandidate, setViewCandidate] = useState([]);
  const [openJobDetail, setOpenJobDetails] = useState({});
  const [applyPopup, setApplyPopup] = useState(false);
  const [fullScreenLoader, setFullScreeenLoader] = useState(false);
  let userToken = JSON.parse(localStorage.getItem("userToken"));
  let adminId = JSON.parse(localStorage.getItem("adminId"));

  const limit = 10;
  //----------------------------------------- Login API --------------------------------
  const LoginApi = (state) => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: state.email,
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "user/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          localStorage.setItem("userInfo", JSON.stringify(result.result));
          localStorage.setItem("userToken", JSON.stringify(result.token));
          localStorage.setItem("id", JSON.stringify(result.result._id));
          const userInf = JSON.parse(localStorage.getItem("userInfo"));
          setProfile(userInf);
          setButtonLoader(false);
          toast.success(result.message);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
          localStorage.removeItem("id");
        } else {
          setResultLoginState(result.message);
          setButtonLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Forgot password API---------------------------------

  const forgotPasswordApi = (state) => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: state.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `user/forgotPassword`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setButtonLoader(false);
          toast.success(result.message);

          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
        // // else if (
        // //   result.message == "TOKEN_EXPIRED" ||
        // //   result.message == "INVALID_TOKEN"
        // // )
        // {
        //   toast.error("Session Expired,Login Again!");
        //   navigate("/");
        //   localStorage.removeItem("userInfo");
        //   localStorage.removeItem("userToken");
        // }
      })
      .catch((error) => console.log("error", error));
  };

  //------------------signup1 api-----------------------
  const signUpApi = (state) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      vendorname: state.vendorname,
      website: state.website,
      address: state.address,
      contactpersonname: state.contactpersonname,
      contactpersonphone: state.contactpersonphone,
      email: state.email,
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "vendor/signup", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          // localStorage.setItem("userInfo", JSON.stringify(result.Result));
          // localStorage.setItem("id", JSON.stringify(result.Result._id));
          setThankyouModal(true);
          setButtonLoader(false);
          // toast.success(result.message);
          // setTimeout(() => {
          //   navigate("/");
          // }, 1000);
        } else {
          setResultLoginState(result.message);
          setButtonLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Reset Password API---------------------------------

  const resetPasswordApi = (state, userId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `user/resetpassword/${userId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setButtonLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Create Password
  const CreatePasswordApi = (state) => {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "resetpassword", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Add candidate API---------------------------------

  const AddCandidateApi = (state) => {
    setDisableSave(true);
    var myHeaders = new Headers();
    let vendorid = JSON.parse(localStorage?.getItem("id"));
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("name", state.name);
    formdata.append("linkedinprofile", state.linkedinprofile);
    formdata.append("email", state.email);
    formdata.append("resumelink", state.resumelink);
    formdata.append("primarytechnology", state.primarytechnology);
    formdata.append("totalExp[years]", state["totalExp"].years);
    formdata.append("totalExp[months]", state["totalExp"].months);
    formdata.append("phonenumber", state.phonenumber);
    formdata.append("rate", state.rate);
    formdata.append("joiningtime", state.joiningtime);
    formdata.append("relevantExp[years]", state["relevantExp"].years);
    formdata.append("relevantExp[months]", state["relevantExp"].months);
    formdata.append("status", state.status);
    formdata.append("pf", state.pf);
    formdata.append("pannumber", state.pannumber);
    formdata.append("aadhar", state.aadhar);
    formdata.append("resume", state.resume);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/addCandidate/${vendorid}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/candidate_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //
  // ----------------------------------------get candidate api----------------------

  const getCandidateById = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/getCandidate`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCandidate(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //---------------------------------------update candidate api-------------------------------
  const updateCandidateApi = (state, id) => {
    setId(id);
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("name", state.name);
    formdata.append("linkedinprofile", state.linkedinprofile);
    // formdata.append("linkedinprofile", state.linkedinprofile?.includes("https://") ? state.linkedinprofile : "https://" + state.linkedinprofile);
    // formdata.append("resumelink", state.resumelink?.includes("https://") ? state.resumelink : "https://" + state.resumelink);
    formdata.append("email", "vikas@parangat.com");
    formdata.append("resumelink", state.resumelink);
    if (typeof state.primarytechnology === "object") {
      formdata.append("primarytechnology", state?.primarytechnology?._id);
    }
    if (typeof state.primarytechnology === "string") {
      formdata.append("primarytechnology", state?.primarytechnology);
    }
    formdata.append("totalExp[years]", state["totalExp"].years);
    formdata.append("totalExp[months]", state["totalExp"].months);
    formdata.append("phonenumber", state.phonenumber);
    formdata.append("rate", state.rate);
    formdata.append("joiningtime", state.joiningtime);
    formdata.append("relevantExp[years]", state["relevantExp"].years);
    formdata.append("relevantExp[months]", state["relevantExp"].months);
    if (typeof state.status === "object") {
      formdata.append("status", state?.status?._id);
    }
    if (typeof state.status === "string") {
      formdata.append("status", state?.status);
    }
    formdata.append("pf", state.pf);
    formdata.append("pannumber", state.pannumber);
    formdata.append("aadhar", state.aadhar);
    formdata.append("resume", state.resume);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/updateCandidate/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/candidate_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------delete candidate api---------------------------
  const deleteCandidateApi = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/deletecandidate/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          candidatePaginationApi(1, limit);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search candidate api------------
  const searchCandidateApi = (key) => {
    var myHeaders = new Headers();
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `candidate/allcandidate/${userInfo?._id}?key=${key}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCandidate(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------candidate Pagination Api-----------
  const candidatePaginationApi = (page, limit) => {
    setLoader(true);
    let vendorid = JSON.parse(localStorage.getItem("id"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL +
        `candidate/allcandidate/${vendorid}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);

        if (result.statusCode === 200) {
          setCandidate(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  // ---------------allcandidateapi--------------
  const getAllCandidateApi = () => {
    let vendorid = JSON.parse(localStorage.getItem("id"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/allcandidate/${vendorid}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setAllCandidate(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //---------------------add job api-----------------
  const AddJobApi = (state) => {
    setDisableSave(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);
    var raw = JSON.stringify({
      jobtitle: state.jobtitle,
      exp_min_max: state.exp_min_max,
      positions: state.positions,
      worknature: state.worknature,
      location: state.location,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `job/addJob`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/job_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //------------applycandidateapi--------

  const applyCandidateApi = (id, state) => {
    setDisableSave(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);
    var raw = JSON.stringify({
      candidate_id: state,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/applycandidate/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            setApplyJobModal(false);
            navigate("/job_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //------------get applied candidatepagination api--------
  const appliedJobsPaginationApi = (page, limit) => {
    setLoader(true);
    let vendorid = JSON.parse(localStorage.getItem("id"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `candidate/getappliedjob?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setAppliedJobs(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  // ----------------------------------------get job api----------------------

  const getJobApi = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `jobs/getJob`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCandidate(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //---------------------------------------update job api-------------------------------
  const updateJobApi = (state, id) => {
    setId(id);
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);

    var raw = JSON.stringify({
      jobtitle: state.jobtitle,
      exp_min_max: state.exp_min_max,
      positions: state.positions,
      worknature: state.worknature,
      location: state.location,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `job/updateJob/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/job_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------delete job api---------------------------
  const deleteJobApi = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `job/deleteJob/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          jobPaginationApi(1, limit);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search job api------------
  const searchJobApi = (key) => {
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `user/enablejob/?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setJob(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------job Pagination Api-----------
  const jobPaginationApi = (page, limit) => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `user/enablejob?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setJob(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Candidate, job Count Api
  const AllCountApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/total/${userInfo?._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setAllCount(result.Result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------------import User Api----------------------

  const importJobApi = (state) => {
    setDisableSave(true);

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("file", state.file);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `jobs/add_job`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);

          setTimeout(() => {
            setDisableSave(false);
            navigate("/job_list");
          }, 2000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // get all technologies list -----------------------------------
  const getTechnologyApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `user/getalltechnology`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setTechnologyList(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // get status list ----------------------------------------------------
  const getStatusListApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `user/getallstatus`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setStatusList(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //get view candidate api
  const viewCandidatePaginationApi = (page, limit, id) => {
    setLoader(true);
    let vendorid = JSON.parse(localStorage.getItem("id"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `candidate/getall/${id}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);

        if (result.statusCode === 200) {
          setViewCandidate(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------search view candidate api-----
  // const searchViewCandidateApi = (key) => {
  //   var myHeaders = new Headers();

  //   myHeaders.append("token", userToken);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };
  //   fetch(BASE_URL + `candidate/getall/?key=${key}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.statusCode === 200) {
  //         setViewCandidate(result.result);
  //       } else if (
  //         result.message == "TOKEN_EXPIRED" ||
  //         result.message == "INVALID_TOKEN"
  //       ) {
  //         toast.error("Session Expired,Login Again!");
  //         setCheckLogin(false);
  //         navigate("/");
  //         localStorage.removeItem("userInfo");
  //         localStorage.removeItem("userToken");
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // impot candidates api -----------------------------------------------
  const importCandidatesApi = (state) => {
    setDisableSave(true);
    let id = JSON.parse(localStorage.getItem("userInfo"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("file", state.file);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `candidate/import/${id?._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/candidate_list");
          }, 2000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Session Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.clear();
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // -----------------all jobs api without token------------------------------
  const getAllOpenJobsApi = (page, limit) => {
    setLoader(true);
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `job/getalljobs?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setOpenJobs(result.result);
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
          setGlobalLoader(false);
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
//akshay fun



const applycandidatejob=(data)=>{
  
  console.log("+++++++++++++auth",data)
  const formdata = new FormData();
  formdata.append("name", data.name);
  formdata.append("email",data.email);
  // formdata.append("resume", "66752f31df172fa32c61718a_akshay (1).pdf");
  formdata.append("phonenumber", data.phonenumber);
  formdata.append("primarytechnology", data.primarytechnology);
  formdata.append("linkedinprofile",data.linkedinprofile);
  formdata.append("relevantExp[years]", data["relevantExp"].years);
  formdata.append("relevantExp[months]", data["relevantExp"].months);
  formdata.append("totalExp[years]", data["totalExp"].years);
  formdata.append("totalExp[months]", data["totalExp"].months);
  formdata.append("resumelink", data.resumelink);
  formdata.append("ctc", data.ctc);
  formdata.append("resume", data.resume);
  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow"
  };
  
  fetch("https://api.getdeveloper.in//job/applyCandidate/", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      
      if (result.statusCode === 200) {
        setFullScreeenLoader(false);
        toast.success("Applied Successfully");
        setTimeout(() => {
          setDisableSave(false);
          setApplyPopup(true);
        }, 2000);
      } else if (result.statusCode === 413) {
        setFullScreeenLoader(false);
        toast.error("please upload less than 1MB");
      } else {
        setFullScreeenLoader(false);
        toast.warning(result.message);
        setDisableSave(false);
        setButtonLoader(false);
      }
    })
    .catch((error) => console.error(error));
}


  //----------apply user job api-------------------

  var myHeaders = new Headers();

  const applyUserJobApi = (state, jobid, technology) => {
    setFullScreeenLoader(true);
    setDisableSave(true);
    // myHeaders.append("token", userToken);
    var formdata = new FormData();
    formdata.append("name", state.name);
    formdata.append("linkedinprofile", state.linkedinprofile);
    formdata.append("resumelink", state.resumelink);
    formdata.append("phonenumber", state.phonenumber);
    formdata.append("rate", state.ctc);
    formdata.append("joiningtime", state.joiningtime);
    formdata.append("relevantExp[years]", state["relevantExp"].years);
    formdata.append("relevantExp[months]", state["relevantExp"].months);
    formdata.append("totalExp[years]", state["totalExp"].years);
    formdata.append("totalExp[months]", state["totalExp"].months);
    formdata.append("status", "64f997e6d3d3c1cadc894a75");
    formdata.append("primarytechnology", technology);
    formdata.append("email", state.email);
    formdata.append("resume", state.resume);
    // formdata.append("fileFormat", state.fileFormat);
    state.videoIntro && formdata.append("videoIntro", state.videoIntro);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`https://api.getdeveloper.in/job/applyjob/${jobid}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setFullScreeenLoader(false);
          toast.success("Applied Successfully");
          setTimeout(() => {
            setDisableSave(false);
            setApplyPopup(true);
          }, 2000);
        } else if (result.statusCode === 413) {
          setFullScreeenLoader(false);
          toast.error("please upload less than 1MB");
        } else {
          setFullScreeenLoader(false);
          toast.warning(result.message);
          setDisableSave(false);
          setButtonLoader(false);
        }
      })

      .catch((error) => {
        console.log("error", error);
        // if(error.includes("Request Entity Too Larg")){
        //   toast.error("please upload less than 1MB");
        // }
        setDisableSave(false);
      });
  };
  console.log(fullScreenLoader, "abcd");

  //getopenjp detail api-------------
  const getOpenJobDetailsApi = (jobname) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `job/getjob/${jobname}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          setOpenJobDetails(result.result);
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (new URLSearchParams(window.location.search).get("user_id")) {
      localStorage.setItem(
        "user_id",
        new URLSearchParams(window.location.search).get("user_id")
      );
    }
  }, [new URLSearchParams(window.location.search).get("user_id")]);

  //  sidbar active
  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/terms-condition" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname === ""
    ) {
      localStorage.clear();
      // localStorage.removeItem("userInfo");
      // localStorage.removeItem("id");
      // localStorage.removeItem("userToken");
    } else if (window.location.pathname === "/reset-password") {
      setCheckLogin(false);
    } else if (
      window.location.pathname === "/candidate" ||
      window.location.pathname === "/candidate_list" ||
      window.location.pathname === "/add_candidate" ||
      window.location.pathname ===
        "/update_candidate/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/dashboard" ||
      window.location.pathname === "/job_list" ||
      window.location.pathname === "/applied-jobs" ||
      window.location.pathname === "/add_job" ||
      window.location.pathname ===
        "/update_job/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/import_job" ||
      window.location.pathname === "/profile" ||
      window.location.pathname === "/import-candidates" ||
      window.location.pathname ===
        "/viewcandidate-list/" + window.location.pathname.split("/").pop()
    ) {
      setCheckLogin(true);
    } else {
      setCheckLogin(false);
    }
  }, [window.location.pathname]);

  // All count api load by default
  useEffect(() => {
    if (
      window.location.pathname === "" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/forgot-password" ||
      window.location.pathname === "/forgot-password/" ||
      window.location.pathname === "/create-password" ||
      window.location.pathname === "/create-password/" ||
      window.location.pathname === "/reset-password" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signup/" ||
      window.location.pathname === "/jobs" ||
      window.location.pathname === "/jobs/" ||
      // window.location.pathname ===
      //   "/apply/" + window.location.pathname.split("/").pop() ||
      // window.location.pathname ===
      //   "/apply" + window.location.pathname.split("/").pop() ||
      // window.location.pathname ===
        "/reset-password/" + window.location.pathname.split("/").pop() ||
      window.location.pathname ===
        "/upload_video/" + window.location.pathname.split("/").pop()
    ) {
    } else {
      AllCountApi();
    }
  }, [candidate, job, appliedJobs]);

  return (
    <DataContext.Provider
      value={{
        LoginApi,
        resultLoginState,
        setResultLoginState,
        ButtonLoader,
        setButtonLoader,
        checkLogin,
        setCheckLogin,
        logout,
        setLogout,
        CreatePasswordApi,
        ButtonLoader,
        setButtonLoader,
        AddCandidateApi,
        updateCandidateApi,
        deleteCandidateApi,
        globalLoader,
        getCandidateById,
        searchCandidateApi,
        candidate,
        setCandidate,
        deleteBtn,
        setDeleteBtn,
        deleteBtnId,
        setDeleteBtnId,
        AllCountApi,
        allCount,
        disableSave,
        pageCount,
        setPageCount,
        totalCount,
        setTotalCount,
        candidatePaginationApi,
        resetPasswordApi,
        // getCountryApi,
        // countryList,
        // setCountryList
        AddJobApi,
        getJobApi,
        updateJobApi,
        deleteJobApi,
        searchJobApi,
        jobPaginationApi,
        importJobApi,
        currentPage,
        setCurrentPage,
        job,
        setJob,
        forgotPasswordApi,
        getTechnologyApi,
        technologyList,
        setTechnologyList,
        getStatusListApi,
        statusList,
        setStatusList,
        importCandidatesApi,
        profile,
        getAllCandidateApi,
        allCandidate,
        setAllCandidate,
        applyCandidateApi,
        applyJobModal,
        setApplyJobModal,
        appliedJobs,
        setAppliedJobs,
        appliedJobsPaginationApi,
        viewJDModal,
        setViewJDModal,
        signUpApi,
        thankyouModal,
        setThankyouModal,
        getAllOpenJobsApi,
        openJobs,
        setOpenJobs,
        loader,
        viewcandidate,
        setViewCandidate,
        viewCandidatePaginationApi,
        // searchViewCandidateApi,
        applyUserJobApi,
        openJobDetail,
        setOpenJobDetails,
        getOpenJobDetailsApi,
        applyPopup,
        setApplyPopup,
        fullScreenLoader,
        setFullScreeenLoader,
        userToken,
        applycandidatejob
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataProvider;
