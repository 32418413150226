import React, { useContext, useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { DataContext } from "../../context/Datastate";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import nodata from "../Assets/nodata.png";
import { ToastContainer, toast } from "react-toastify";

const ApplyJobModal = ({ selectedJob }) => {
  const handleClose = () => {
    setApplyJobModal(false);
  };

  const {
    applyjob,
    setApplyJob,
    allCandidate,
    setJobId,
    getAllCandidateApi,
    applyCandidateApi,
    applyJobModal,
    setApplyJobModal,
  } = useContext(DataContext);

  const [appliedCandidate, setAppliedCandidate] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...applyjob].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setApplyJob(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  const handleCheckboxChange = (id) => {
    setAppliedCandidate((prevIds) => {
      if (prevIds.includes(id)) {
        // If the ID is already in the array, remove it
        return prevIds.filter((itemId) => itemId !== id);
      } else {
        // If the ID is not in the array, add it
        return [...prevIds, id];
      }
    });
  };
  const handleSelectAll = () => {
    if (selectAll) {
      setAppliedCandidate([]);
    } else {
      const allIds = allCandidate.map((item) => item._id);
      setAppliedCandidate(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSubmit = () => {
    if (appliedCandidate?.length > 0) {
      applyCandidateApi(selectedJob._id, appliedCandidate);
    } else {
      toast.error("Please select candidates to apply");
    }
  };

  useEffect(() => {
    getAllCandidateApi();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="absolute w-[calc(100vw-173px)] h-screen bg-[#00000090] z-[5] ">
        <div className="w-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 z-[6] -translate-y-1/2 rounded-md bg-white px-4 ">
          <AiOutlineCloseCircle
            className="close cursor-pointer absolute right-5 top-5 text-[20px] text-red-600 z-[99]"
            onClick={() => handleClose()}
          />
          <div className="pt-5 flex justify-between items-start">
            <h1 className="text-xl text-[#202223] font-semibold">
              Apply candidates
            </h1>
          </div>
          {/*--------- table list--------- */}
          {/* {globalLoader ? (
            <GlobalLoader />
          ) : ( */}
          {allCandidate?.filter(
            (v) => v.primarytechnology?._id === selectedJob.technology?._id
          ).length > 0 ? (
            <>
              <div className="h-[75vh] relative overflow-y-auto">
                <table className="w-full table-auto bg-white border-collapse shadow-md rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className=" text-left bg-theme-light text-theme-color text-xs font-normal  ">
                      <th className=" px-4 py-2 text-center rounded-tr-lg">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className=" px-4 py-3 flex items-center">
                        <div className="flex items-center gap-4">
                          <p>Candidate Name</p>
                          <div className="px-2">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "name",
                                  "asc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "name",
                                  "desc"
                                )}`}
                                onClick={() => sortData("name")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Designation</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "primarytechnology",
                                  "asc"
                                )}`}
                                onClick={() => sortData("primarytechnology")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "primarytechnology",
                                  "desc"
                                )}`}
                                onClick={() => sortData("primarytechnology")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Experience</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "totalExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "totalExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>
                            Rel <br />
                            Exp.
                          </p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "relevantExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "relevantExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("relevantExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Rate</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "joiningtime",
                                  "asc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "joiningtime",
                                  "desc"
                                )}`}
                                onClick={() => sortData("joiningtime")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 py-2 text-center rounded-tr-lg">
                        Joining Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {allCandidate
                      ?.filter(
                        (v) =>
                          v.primarytechnology?._id ===
                          selectedJob.technology?._id
                      )
                      .map((item) => (
                        <tr className="border-t text-left text-[16px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                          <td className="px-4 py-2">
                            <input
                              type="checkbox"
                              onChange={() => handleCheckboxChange(item._id)}
                              checked={
                                appliedCandidate.includes(item._id) || selectAll
                              }
                            />
                          </td>
                          <td className="px-4 py-2">{item?.name}</td>
                          {/* <td className="px-4 py-2">{item?.linkedinprofile}</td>
                  <td className="px-4 py-2">{item?.resumelink}</td> */}
                          <td className="px-4 py-2">
                            {item?.primarytechnology?.technologyname}
                          </td>
                          <td className="px-2 py-2">
                            {item.hasOwnProperty("totalExp")
                              ? `${item?.totalExp?.years}. ${item?.totalExp?.months}`
                              : "0.0"}
                          </td>
                          <td className="px-2 py-2">
                            {item.hasOwnProperty("relevantExp")
                              ? `${item?.relevantExp?.years}. ${item?.relevantExp?.months}`
                              : "0.0"}
                          </td>
                          <td className="px-4 py-2">{item?.rate}</td>
                          <td className="px-4 py-2">{item?.joiningtime}</td>
                          {/* <td className="px-4 py-2">
                        <div className="flex items-center  justify-center  gap-2 text-black ">
                          <FiEdit2
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            onClick={() => {
                              handleUpdateTechnology(item);
                            }}
                          />

                          <FiTrash
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            onClick={() => {
                              setDeleteBtn(true);
                              setDeleteBtnId(item?._id);
                            }}
                          />
                          <span>
                            <label
                              title={item.isActive ? "disable" : "enable"}
                              class="flex items-center cursor-pointer"
                            >
                              <div class="relative">
                                <input
                                  type="checkbox"
                                  title={item.isActive ? "disable" : "enable"}
                                  className="sr-only dot"
                                  checked={item?.isActive}
                                  onClick={() => {
                                    setEnableModal(true);
                                    setSingleTechnology(item);
                                  }}
                                />
                                <div class="w-[24px] h-[11px] bg-gray-400 rounded-full shadow-inner"></div>
                                <div class="dot vend_act absolute w-[18px] h-[18px] bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                              </div>
                            </label>
                          </span>
                        </div>
                      </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="p-4 ">
                <div className="text-center">
                  <button
                    type="button"
                    // disabled={!buttonDisabled}
                    className="bg-theme-color disabled:opacity-50 px-10 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
                    onClick={() => handleSubmit()}
                  >
                    Apply
                  </button>
                  {/* {/ </Link> /} */}
                </div>
              </div>
            </>
          ) : (
            <div className="flex w-full bg-white">
              <div className="w-full p-8  bg-white overflow-y-scroll h-[45vh] ">
                <div className="border border-dashed border-[#BABFC3] rounded flex flex-col justify-center items-center h-[35vh]">
                  <img src={nodata} alt="nodata" />
                  <span className="text-[#6D7175] inline-block font-light text-xl pt-4">
                  Please add Candidates from the Left Menu first to apply.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplyJobModal;
