import React from 'react'
import './Loader.css';

const Loader = () =>  {
  
//     <div class="spinner-box w-full h-[90vh] flex justify-center">
//   <div class="pulse-container">  
//     <div class="pulse-bubble pulse-bubble-1"></div>
//     <div class="pulse-bubble pulse-bubble-2"></div>
//     <div class="pulse-bubble pulse-bubble-3"></div>
//   </div>
// </div>
{/* <div class="spinner-boxx w-full h-[90vh] flex justify-center">
  <div class="circle-r animate-spin w-24 h-24">
    <div class="circle-coree w-full h-full"></div>
  </div>  
</div> */}

return (

  <>
  <div className="relative w-full h-screen ">
<div id="container" className="absolute top-1/2 left-1/2 ">
  <div className="flex gap-2 items-center">
<div id="ball-1" className="circle"></div>
<div id="ball-2" className="circle"></div>
<div id="ball-3" className="circle"></div>
</div>
</div>
</div>
  </>
)

}


export default Loader