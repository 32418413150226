export const isEmptyObject = (objectName) => {
  return JSON.stringify(objectName) === "{}";
};

export const checkNameFormat = (name) => {
  const regex = /^[a-zA-Z][a-zA-Z. ]*$/;
  // /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
  return regex.test(name.trim());
};
export const isPhoneValid = (phone) => {
  const regexForIndianMobile = /^[6-9]\d{9}$/;
  return regexForIndianMobile.test(phone);
};

export const isEmailValid = (email) => {
  const regexForEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regexForEmail.test(email);
};

export const isURLVaid = (url) => {
  const regexForURL =
    /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;
  return regexForURL.test(url);
};

export const isPANValid = (pan) => {
  const regexForPAN = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  return regexForPAN.test(pan);
};

export const isPFValid = (pf) => {
  const regexForPF =
    /^[A-Z]{2}[\s\/]?[A-Z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7}$/;
  return regexForPF.test(pf);
};

export function convertISODateToFormattedDate(isoDateString) {
  const date = new Date(isoDateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
