export const UPLOAD_ICON=({className: classes})=>(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
    <path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z" fill="currentColor"/>
  </svg>
);

export const BACWARD_ARROW=({ className: classes })=>(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0002 9.00025H5.41425L8.70725 5.70725C9.09825 5.31625 9.09825 4.68425 8.70725 4.29325C8.31625 3.90225 7.68425 3.90225 7.29325 4.29325L2.29325 9.29325C1.90225 9.68425 1.90225 10.3163 2.29325 10.7073L7.29325 15.7072C7.48825 15.9022 7.74425 16.0002 8.00025 16.0002C8.25625 16.0002 8.51225 15.9022 8.70725 15.7072C9.09825 15.3162 9.09825 14.6842 8.70725 14.2933L5.41425 11.0002H17.0002C17.5532 11.0002 18.0002 10.5522 18.0002 10.0002C18.0002 9.44825 17.5532 9.00025 17.0002 9.00025Z" fill="#5C5F62"/>
  </svg>
  );