import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import logo from "../Assets/logo.png";
import coverImage from "../Assets/Vector5.png";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { DataContext } from "../../context/Datastate";

const CreatePassword = () => {
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const { CreatePasswordApi } = useContext(DataContext);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [matchPasswordError, setMatchPasswordError] = useState(false);

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });

  const validatePassword = () => {
    if (state.password === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const validateConfirmPassword = () => {
    if (state.confirmPassword === "") {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  };

  const matchPassword = () => {
    if (state.confirmPassword === "") {
      setMatchPasswordError(false);
    } else if (state.password != state.confirmPassword) {
      setMatchPasswordError(true);
    } else {
      setMatchPasswordError(false);
    }
  };

  const buttonDisabled =
    state.password === "" ||
    state.confirmPassword === "" ||
    state.password != state.confirmPassword;

  const handleChangePassword = () => {
    if (state.password === state.confirmPassword) {
      setMatchPasswordError(false);
      CreatePasswordApi(state);
    } else {
      setMatchPasswordError(true);
    }
  };

  return (
    <>
      <div
        className="w-full h-screen flex items-center justify-center flex-col bg-contain bg-top bg-no-repeat"
        style={{ backgroundImage: `url(${coverImage})` }}
      >
        <div className="w-[100%] md:w-[60%] lg:w-[50%] xl:w-[40%] bg-whiterounded-md">
          <img
            src={logo}
            className=" w-[50%] m-auto pb-5 px-5"
            alt="logo"
          ></img>
        </div>
        <div className="w-[80%] md:w-[60%] lg:w-[50%] xl:w-[30%] bg-white shadow hover:shadow-lg rounded-md">
          <div className=" py-2 rounded-t-md">
            <h1 className="text-center text-[20px] 2xl:text-[30px]  font-bold  mb-2">
              Create Password
            </h1>
            <p className="text-center text-sm mb-3 ">
              To avoid security risks, we urge you to create a new password
            </p>
          </div>
          <hr />
          <div className="p-9 md:p-8">
            <form>
              <label className="relative block text-sm font-medium ">
                <span className=" text-theme-color ">*</span> Password
                {/* <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FiLock className="h-4 w-4 text-theme-color" />
                </span> */}
                <input
                  type={password === true ? "text" : "password"}
                  className=" block w-full px-3 pl-4 py-3 mt-2 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 "
                  placeholder="Enter Password"
                  name="password"
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value });
                  }}
                  onKeyUp={() => validatePassword()}
                />
                <span
                  className="absolute bottom-3 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setPassword(!password)}
                >
                  {password === true ? (
                    <FaRegEye className="h-5 w-5 fill-theme-color" />
                  ) : (
                    <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                  )}
                </span>
              </label>
              {passwordError && (
                <small className="text-left text-red-600">Enter Password</small>
              )}
              <label className="relative block mt-6 text-sm font-medium">
                <span className=" text-theme-color ">*</span>Confirm Password
                {/* <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FiLock className="h-4 w-4 text-theme-color" />
                </span> */}
                <input
                  type={confirmPassword === true ? "text" : "password"}
                  className="mt-2 block w-full px-3 pl-4 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 "
                  placeholder="Enter Confirm Password"
                  name="confirmPassword"
                  onChange={(e) => {
                    setState({ ...state, confirmPassword: e.target.value });
                  }}
                  onKeyUp={() => {
                    validateConfirmPassword();
                    matchPassword();
                  }}
                />
                <span
                  className="absolute bottom-3 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  {confirmPassword === true ? (
                    <FaRegEye className="h-5 w-5 fill-theme-color" />
                  ) : (
                    <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                  )}
                </span>
              </label>
              {confirmPasswordError && (
                <div>
                  <small className="text-left text-red-600">
                    Enter Confirm Password
                  </small>
                </div>
              )}

              {matchPasswordError && (
                <div>
                  <small className="text-left text-red-600">
                    Password and Confirm Password not match
                  </small>
                </div>
              )}
              <div className="flex justify-center mt-8 mb-2">
                <button
                  type="button"
                  className="bg-theme-color text-white p-2 w-full rounded-lg  font-medium disabled:opacity-40 cursor-pointer"
                  onClick={() => handleChangePassword()}
                  disabled={buttonDisabled}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <small className=" mt-4">
          © {new Date().getFullYear()} Get Developers
        </small>
      </div>
    </>
  );
};
export default CreatePassword;
