import React, { useContext } from "react";
import { DataContext } from "../../context/Datastate";

const FullScreenLoader = () => {

  return (
    <>
      <div className="fixed top-0 h-screen left-0 w-full bg-[#00000062] z-[8]">
        <div className="absolute top-1/2 left-1/2 -translate-x-2/4 z-[9] -translate-y-2/4">
          <div className="flex gap-2 items-center">
            <div id="ball-1" className="circle"></div>
            <div id="ball-2" className="circle"></div>
            <div id="ball-3" className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FullScreenLoader;
