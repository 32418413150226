import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import { ToastContainer } from "react-toastify";

const AddJob = () => {
  const {
    AddJobApi,
    // getJobApi,
    job,
    disableSave,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);

  const [state, setState] = useState({
    jobtitle: "",
    exp_min_max: "",
    positions: "",
    worknature: "",
    location: "",
  });

  
  const buttonDisabled =
    state.jobtitle !== "" &&
    state.exp_min_max !== "" &&
    state.positions !== "" &&
    state.worknature !== "" &&
    state.location !== "";

  const handleSubmit = () => {
    // if (!disableSave)
     AddJobApi(state);
  };

  useEffect(() => {
    // getJobApi();
    // getCountryApi();
    // job?.filter((element) => {
    //   return;
    // });
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="flex w-full bg-white">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/job_list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                Job info{" "}
              </h1>
            </div>
          </div>
          <div className="flex space-x-10">
            <div className="w-1/2 space-y-3">
              <h2 className="font-semibold text-lg">Job details</h2>
              <hr />
              <div className="">
                <div>
                  <label
                    htmlFor="title"
                    className="text-sm px-1 font-semibold "
                  >
                    Job title *
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Enter your job title"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.jobtitle}
                    onChange={(e) => {
                      setState({ ...state, jobtitle: e.target.value });
                    }}
                  />
                </div>
                {/* <div>
                  <label
                    htmlFor="last-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Last name *
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    name="lastname"
                    placeholder="Enter your last name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.lastname}
                    onChange={(e) => {
                      setState({ ...state, lastname: e.target.value });
                    }}
                  />
                </div> */}

                <div>
                  <label
                    htmlFor="experience"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Experience *
                  </label>
                  <input
                    type="text"
                    name="experience"
                    placeholder="Enter your experience"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.exp_min_max}
                    onChange={(e) => {
                      setState({ ...state, exp_min_max: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="position"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Position *
                  </label>
                  <input
                    type="text"
                    id="position"
                    name="position"
                    placeholder="Position"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.positions}
                    onChange={(e) => {
                      setState({ ...state, positions: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="WorkNature"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Work Nature *
                  </label>
                  <input
                    id="WorkNature"
                    type="text"
                    name="WorkNature"
                    placeholder="Enter your worknature"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.worknature}
                    onChange={(e) => {
                      setState({ ...state, worknature: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="location"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Location *
                  </label>
                  <select
                    id="location"
                    type="text"
                    name="location"
                    placeholder="Location"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.location}
                    onChange={(e) => {
                      setState({ ...state, location: e.target.value });
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Choose Location
                    </option>
                    <option value="Noida">Noida</option>
                    <option value="Gurgoan">Gurgoan</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                
              </div>
            </div>
            <div className="w-1/2 pt-14">
              

            </div>
          </div>
          <div className="">
            <Link to="/job_list">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              disabled={!buttonDisabled}
              className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddJob;
