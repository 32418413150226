import React, { useState,useContext } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import { ToastContainer } from 'react-toastify'
import ExcelLogo from "../Assets/excel logo.png"

const ImportJob = () => {
  const {importJobApi,disableSave}=useContext(DataContext);
  const [state,setState]=useState({
    file:"",
  })

  const onButtonClick = () => {
   
    fetch('sample file.xlsx').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'sample file.xlsx';
            alink.click();
        })
    })
}
  const handleSubmit=()=>{
    if(!disableSave)
  importJobApi(state);
  }
 
  return (
    <>
    <ToastContainer/>
      <div className="flex w-full">

        <div className="w-full px-7 py-2 ">
          <div className="flex py-2 items-center justify-between" >
            <div className="flex">
            <Link to={"/job_list"}>
              <div className="w-8 pt-1">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2">
              <h1 className="text-xl px-2 ">Import Job</h1>
            </div>
            </div>
            <button type="button" className="flex items-center bg-[#34a85314] gap-2 h-9 rounded cursor-pointer px-2" onClick={onButtonClick}>
              <img src={ExcelLogo} alt="excel-logo" />
              <span className="text-[#34A853] font-medium  text-sm ">Download Sample</span>
            </button>
          </div>
          <div className="bg-white p-5 rounded-md">
            <div className="w-full h-72 border-2 border-dashed hover:border-theme-color border-gray-500 rounded-md text-center">
              <div className="flex justify-center pt-32">
                {/* <button className="text-theme-color bg-orange-200 p-2 px-4 py-1  text-sm rounded">
                Choose Excel File
              </button> */}
                <label htmlFor="choose_banner" accept="image/png, image/gif, image/jpeg, image/svg" className='text-theme-color cursor-pointer px-2 py-1  text-sm '><span className="bg-theme-light-color px-2 py-1">{(state.file=="") ? "No File choosen" : state.file.name}</span>
                  <p className='text-sm pt-1 text-gray-400'>Accepts .XLM , .xlxs , csv</p>
                  <input id="choose_banner" type='file' className='text-theme-color hidden  bg-orange-200 px-1 text-sm rounded-md '
                  onChange={(e)=>{
                  
                    setState({...state,file:e.target.files[0]})
                  }}
                  />
                </label>
              </div>
              {/* <div className="flex justify-center pt-1">
              <p className="text-base text-gray-400  ">
                Accepts .XLM , .xlxs , csv
              </p>
              </div> */}
            </div>
          </div>
          <div className="pt-2">
            <Link to={"/job_list"}>
              <button className="border-2 hover:bg-gray-300  border-[#BABFC3] px-8 text-sm py-2 rounded">
                Back
              </button>
            </Link>
            <button className="bg-theme-color px-8 text-sm py-2 rounded hover:bg-orange-500 text-white ml-3"
            onClick={()=>{handleSubmit()}}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportJob;
