import React, { useContext, useState } from 'react'
import { DataContext } from '../../context/Datastate'
import thku from "../Assets/thankyou2.png"
import { useNavigate } from 'react-router-dom'


const ThankyouModal = () => {
  const navigate = useNavigate()
  const {thankyouModal,setThankyouModal} = useContext(DataContext)
  const handleClose = ()=>{
    navigate("/")
  }
  return (
    <>
     <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-screen bg-[#00000062] z-[8]">
                <div className="fixed w-[700px] top-[50%] left-[50%] -translate-x-2/4 z-[9] -translate-y-2/4">
                    <div className="bg-white p-4 rounded z-10">

                        <div className=" flex justify-center">
                           <img src={thku} className='w-[300px]' alt="" />
                        </div>
                        <div className="py-3 text-center">

                            <span className=" block py-2 text-[20px] sm:text-[13px] xs:text-[12px] ">
                               Thank you registering with GetDevelopers !!
                            </span>
                            <p className="text-[#475467] text-[14px] sm:text-[13px] xs:text-[12px]" style={{textWrap: "balance"}}>We are currently verifying your profile details that you entered. This helps ensure you meet the qualifications for roles on our platform. Once reviewed, we will send you a notification on your registered email ID that your profile is approved. This typically takes 1-2 business days.</p>
                        </div>
                        <div className="buttons pb-4 justify-center flex gap-3 mt-6 xs:mt-4 sm:mt-5 w-full">
                            <button
                                className="bg-theme-color w-[25%] rounded-md font-medium md:text-[13px] sm:text-[12px] xs:text-[11px] text-white p-[.4rem_1rem]"
                                onClick={() => handleClose()}
                            >
                                Go Back To Login
                            </button>
                        </div>

                    </div>
                </div>
            </div>
    </>
  )
}

export default ThankyouModal