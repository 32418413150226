import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "../../context/Datastate";
import { ToastContainer } from "react-toastify";

const UpdateCandidate = () => {
  const {
    // getCandidateApi,
    candidate,
    updateCandidateApi,
    disableSave,
    getTechnologyApi,
    technologyList,
    setTechnologyList,
    getStatusListApi,
    statusList,
    setStatusList,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);
  const [editCandidate, setEditCandidate] = useState({});
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [aadharError, setAadharError] = useState("");
  // const [resumeUploadError, setResumeUploadError] = useState("");
  const [websiteError, setWebsiteError] = useState(false);
  const [aadharFileName, setAadharFileName] = useState("");
  // const [resumeFileName, setResumeFileName] = useState("");
  // const [resumeFileName, setResumeFileName] = useState("");
  const [urlError, setUrlError] = useState(null);
  const [linkedinError, setLinkedinError] = useState(null);
  const { id } = useParams();
  const [panNumberTest, setPanNumberTest] = useState(false);
  const [pfNumberTest, setPfNumberTest] = useState(false);

  const handleUpdate = (e) => {
    if (!disableSave) updateCandidateApi(editCandidate, id);
  };

  function validatePAN(panVal) {
    var regpan = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    if (regpan.test(panVal)) {
      return setPanNumberTest(false);
    } else if (panVal == "") {
      return setPanNumberTest(false);
    } else {
      return setPanNumberTest(true);
    }
  }
  function validatePF(pfVal) {
    var regpan =
      /^[A-Z]{2}[\s\/]?[A-Z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7}$/;
    if (regpan.test(pfVal)) {
      return setPfNumberTest(false);
    } else if (pfVal == "") {
      return setPfNumberTest(false);
    } else {
      return setPfNumberTest(true);
    }
  }
  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const handleAadharFileChange = (e) => {
   
    const file = e.target.files[0];
    if (!file) {
      setAadharError("Please select an Aadhar file.");
      return;
    }
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];
    if (!allowedTypes.includes(file.type)) {
      setAadharError(
        "Invalid file type. Please select a PDF, JPG, JPEG, or PNG file."
      );
      setAadharFileName("");
      setEditCandidate({ ...editCandidate, aadhar: "" });
      return;
    }

    if (file.size > 1024 * 1024) {
      setAadharError("File size should be less than 1 MB.");
      setAadharFileName("");
      setEditCandidate({ ...editCandidate, aadhar: "" });
      return;
    }
    setAadharError("");
    setEditCandidate({ ...editCandidate, aadhar: file });
    setAadharFileName(file?.name);
  };

  const validateUrl = (link, fieldname) => {
    const validUrl =
      /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;
    // const validUrl =
    // /^((?!https?:\/\/)(?!www\.)[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)$/;
    if (!validUrl.test(link) && link !== "") {
      setUrlError(fieldname);
    } else {
      setUrlError(null);
    }
  };

  const linkedinUrl = (link, fieldname) => {
    const linkUrl = /^(https?|http):\/\/[^\s/$?#]+(\.[^\s/$?#]+)*\.[^\s$]{2,}$/;
    // const linkUrl = /^((?!https?:\/\/)(?!www\.)[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)$/;
    if (!linkUrl.test(link) && link !== "") {
      setLinkedinError(fieldname);
    } else {
      setLinkedinError(null);
    }
  };

  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false);
    } else if (phoneData == "") {
      return setPhoneFormatTest(false);
    } else {
      return setPhoneFormatTest(true);
    }
  };

  const buttonDisabled =
  urlError ||
  linkedinError ||
  phoneFormatTest ||
    editCandidate.name === "" ||
    editCandidate.linkedinprofile === "" ||
    editCandidate.resumelink === "" ||
    editCandidate.phonenumber === "" ||
    editCandidate.primarytechnology === "" ||
    editCandidate.rate === "" ||
    editCandidate.joiningtime === "" ||
    editCandidate.totalExp?.years === "" ||
    editCandidate.totalExp?.months === "" ||
    editCandidate.status === "" ||
    editCandidate.resume === "" ||
    editCandidate.relevantExp?.years === "" ||
    editCandidate.relevantExp?.months === ""

  useEffect(() => {
    const newArr = candidate.filter((element) => {
      return element?._id === id;
    });
    setEditCandidate({ ...newArr[0] });

    getTechnologyApi();
    getStatusListApi();
    // setCount(newArr[0]?.Biography.split("").length);
  }, []);

  const generateOptions = (start, end,selected) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i} selected={selected}>
          {i}
        </option>
      );
    }
    return options;
  };
console.log(editCandidate,"editCandidate")
  return (
    <>
      <ToastContainer />
      <div className="flex w-full bg-white">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/candidate_list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1 ">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                Candidate info{" "}
              </h1>
            </div>
          </div>
          <div className="flex space-x-10">
            <div className="w-1/3 space-y-3">
              <h2 className="font-semibold text-lg">Candidate details</h2>
              <hr />
              <div className="">
                <div>
                  <label htmlFor="name" className="text-sm px-1 font-semibold ">
                    Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your Candidate name"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate.name}
                    // onChange={(e) => {
                    //   setEditCandidate({
                    //     ...editCandidate,
                    //     name:toTitleCase(e.target.value),
                    //   });
                    // }}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^a-zA-Z\s]/g, ''); 
                      setEditCandidate({ ...editCandidate, name: toTitleCase(filteredInput) });
                    }} 
                  />
                  {editCandidate.name === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter your name
                    </span>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="linkedinprofile"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Linkedin Profile *{" "}
                    <small className="text-gray-500">
                      (https://linkedinprofile.com)
                    </small>
                  </label>
                  {/* <div className="flex ">
                  {!editCandidate?.linkedinprofile?.includes("://")? (<div className="border border-r-0 py-1 pl-2 pr-1 mb-3 leading-[27px] border-gray-300 shadow-sm rounded-tl-md rounded-bl-md placholder:text-slate-400 text-sm h-9">
                    <span>https://</span>
                  </div>) : ""} */}
                  <input
                    type="text"
                    name="linkedinprofile"
                    placeholder="Enter your linkedin profile"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate.linkedinprofile}
                    onKeyUp={() =>
                      linkedinUrl(
                        editCandidate.linkedinprofile,
                        "linkedinprofile"
                      )
                    }
                    onChange={(e) => {
                      setEditCandidate({
                        ...editCandidate,
                        linkedinprofile: e.target.value,
                      });
                    }}
                  />
                  {/* </div> */}
                  {linkedinError === "linkedinprofile" ? (
                    <span className="text-red-600 text-xs">
                      Please enter valid input
                    </span>
                  ) : null}
                  {editCandidate.linkedinprofile === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter linkedin profile
                    </span>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="status"
                    className="text-sm px-1 font-semibold pb-1"
                  >
                    Status *
                  </label>
                  <select
                    id="status"
                    type="text"
                    name="status"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate?.status?._id}
                    onChange={(e) => {
                      setEditCandidate({
                        ...editCandidate,
                        status: e.target.value,
                      });
                    }}
                  >
                    {statusList
                      .filter(
                        (i) =>
                          i.statusname !== "Rejected" &&
                          i.statusname !== "Profile submitted to client"
                      )
                      ?.map((v) => {
                        // {statusList?.map((v) => {

                        if (v?.isActive) {
                          return (
                            <option key={v?._id} value={v?._id}>
                              {v?.statusname}{" "}
                            </option>
                          );
                        }
                        return null;
                      })}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="rate"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Rate *
                  </label>
                  <input
                    id="rate"
                    type="text"
                    name="rate"
                    placeholder="Enter your rate"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate.rate}
                    onChange={(e) => {
                      setEditCandidate({
                        ...editCandidate,
                        rate: e.target.value,
                      });
                    }}
                  />
                  {editCandidate.rate === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter rate
                    </span>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="pf"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    PF Number
                  </label>
                  <input
                    type="text"
                    name="pf"
                    maxLength={22}
                    placeholder="Enter your pf"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate.pf}
                    onChange={(e) => {
                      setEditCandidate({
                        ...editCandidate,
                        pf: e.target.value,
                      });
                    }}
                    onKeyUp={() => validatePF(editCandidate.pf)}
                  />
                  {pfNumberTest ? (
                    <span className="text-red-600 text-sm">
                      Please enter a valid 12 digit Number.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/3 pt-8">
              <label
                htmlFor="totalExp"
                className="pt-2 text-sm px-1 font-semibold pb-1"
              >
                Total Year of Experience *
              </label>
              <div className="flex items-baseline gap-x-2 ">
                <div>
                  <label
                    htmlFor="year"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Year *
                  </label>
                  <select
                    name="totalExp"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate?.totalExp?.years}
                    onChange={(e) =>
                      setEditCandidate({
                        ...editCandidate,
                        totalExp: {
                          ...editCandidate.totalExp,
                          years: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="" >Select Years</option>
                    {generateOptions(0, 30,editCandidate?.totalExp?.years)}
                  </select>
                </div>
                <div className="">
                  <label
                    htmlFor="months"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Months *
                  </label>
                  <select
                    name="monthsofexperience"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate?.totalExp?.months}
                    onChange={(e) =>
                      setEditCandidate({
                        ...editCandidate,
                        totalExp: {
                          ...editCandidate.totalExp,
                          months: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select Months</option>
                    {generateOptions(0, 12,editCandidate?.totalExp?.months)}
                  </select>
                </div>
              </div>
              <div className="pb-2">
                <label
                  htmlFor="phone"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Phone *
                </label>
                <input
                  type="text"
                  maxlength={10}
                  pattern="[0-9]{10}"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                  value={editCandidate.phonenumber}
                  onChange={(e) => {
                    setEditCandidate({
                      ...editCandidate,
                      phonenumber: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePhoneno(editCandidate.phonenumber)}
                />
                {phoneFormatTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
                {editCandidate.phonenumber === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter phone
                    </span>
                  ) : null}
              </div>
              <div>
                <label
                  htmlFor="primarytechnology"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Primary Technology *
                </label>
                <select
                  id="primarytechnology"
                  type="text"
                  name="primarytechnology"
                  placeholder="Enter your primarytechnology"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={editCandidate?.primarytechnology?._id}
                  onChange={(e) => {
                    setEditCandidate({
                      ...editCandidate,
                      primarytechnology: e.target.value,
                    });
                  }}
                >
                  <option value="" selected disabled hidden>
                    Choose primarytechnology
                  </option>
                  {technologyList?.map((v) => {
                    if (v?.isActive) {
                      return (
                        <option key={v?._id} value={v?._id}>
                          {v?.technologyname}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </div>
              <div className="pb-2">
                <label
                  htmlFor="pannumber"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Pan Number
                </label>
                <input
                  type="text"
                  maxLength={10}
                  pattern=""
                  id=""
                  name="pannumber"
                  placeholder="Enter your pan number"
                  className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={editCandidate.pannumber}
                  onChange={(e) => {
                    setEditCandidate({
                      ...editCandidate,
                      pannumber: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePAN(editCandidate.pannumber)}
                />
                {panNumberTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Pan Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-1/3 pt-8">
              <label
                htmlFor="relevantExp"
                className="pt-2 text-sm px-1 font-semibold pb-1"
              >
                Relevant Experience *
              </label>
              <div className="flex items-baseline gap-x-2 ">
                <div>
                  <label
                    htmlFor="year"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Year *
                  </label>
                  <select
                    name="relevantExp"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate?.relevantExp?.years}
                    onChange={(e) =>
                      setEditCandidate({
                        ...editCandidate,
                        relevantExp: {
                          ...editCandidate.relevantExp,
                          years: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select Years</option>
                    {generateOptions(0, 30,editCandidate?.relevantExp?.years )}
                  </select>
                </div>
                <div className="">
                  <label
                    htmlFor="month"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Months *
                  </label>
                  <select
                    name="relevantExp"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editCandidate?.relevantExp?.months}
                    onChange={(e) =>
                      setEditCandidate({
                        ...editCandidate,
                        relevantExp: {
                          ...editCandidate.relevantExp,
                          months: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select Months</option>
                    {generateOptions(0, 12,editCandidate?.relevantExp?.months)}
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="resumelink"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Resume Link *{" "}
                  <small className="text-gray-500">
                    (https://resumelink.com)
                  </small>
                </label>
                {/* <div className="flex ">
                  {!editCandidate?.resumelink?.includes("://")? (<div className="border border-r-0 py-1 pl-2 pr-1 mb-3 leading-[27px] border-gray-300 shadow-sm rounded-tl-md rounded-bl-md placholder:text-slate-400 text-sm h-9">
                    <span>https://</span>
                  </div>) : ""} */}
                <input
                  id="person-name"
                  type="text"
                  name="resumelink"
                  placeholder="Enter your resume link"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={editCandidate.resumelink}
                  onKeyUp={() =>
                    validateUrl(editCandidate?.resumelink, "resumelink")
                  }
                  onChange={(e) => {
                    setEditCandidate({
                      ...editCandidate,
                      resumelink: e.target.value,
                    });
                  }}
                />
                {/* </div> */}
                {urlError === "resumelink" ? (
                  <span className="text-red-600 text-xs">
                    Please enter valid input
                  </span>
                ) : null}
                {editCandidate.resumelink === "" ? (
                    <span className="text-red-600 text-xs">
                      Please enter resumelink
                    </span>
                  ) : null}
              </div>
              <div className="">
                <label
                  htmlFor="joiningtime"
                  className="text-sm px-1 font-semibold pb-1"
                >
                  Joining Time*
                </label>
                <select
                  id="joiningtime"
                  type="text"
                  name="joiningtime"
                  placeholder="Enter your joining time"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={editCandidate.joiningtime}
                  onChange={(e) => {
                    setEditCandidate({
                      ...editCandidate,
                      joiningtime: e.target.value,
                    });
                  }}
                >
                  <option value="" selected disabled hidden>
                    Choose joining time
                  </option>
                  <option value="Immediate">Immediate</option>
                  <option value="15 days">15 days</option>
                  <option value="30 days">30 days</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="aadhar"
                  className="pt-2 text-sm px-1 font-semibold pb-1"
                >
                  Aadhar{" "}
                  <small className="font-normal">
                    (allowed .jpg, .png and .pdf)
                  </small>
                </label>
                <input
                  type="file"
                  name="aadhar"
                  placeholder="Enter your aadhar"
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  // value={editCandidate?.aadhar}
                  onChange={handleAadharFileChange} 
                />
                {aadharError && (
                  <span className="text-red-600 text-xs">{aadharError}</span>
                )}
                <a
                    target="_blank"
                    className="text-theme-color pr-3 underline"
                    href={`https://api.getdeveloper.in/${editCandidate?.aadhar}`}
                  >
                    {" "}
                    View
                  </a>
                {/* {aadharFileName && ( */}
                {editCandidate?.aadhar && (
                  <span className="text-green-600 text-xs">
                    {/* Selected file: {editCandidate?.aadhar} */}
                    Selected file: { typeof editCandidate?.aadhar === "object" ?  editCandidate?.aadhar.name : editCandidate.aadhar}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <Link to="/candidate_list">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              disabled={buttonDisabled}
              className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
              onClick={() => {
                handleUpdate();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCandidate;
