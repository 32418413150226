import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer } from "react-toastify";
import Pagination from "../Pagination/Pagination";
import nodata from "../Assets/nodata.png";
import ApplyJobModal from "./ApplyJobModal";
import ViewJDModal from "./ViewJDModal";
import Loader from "../Loader/Loader";
import NoData from "../NoData/NoData";

const JobList = () => {
  const {
    // getJobApi,
    setJob,
    job,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteJobApi,
    searchJobApi,
    jobPaginationApi,
    totalCount,
    pageCount,
    applyJobModal,
    setApplyJobModal,
    viewJDModal,
    setViewJDModal,
    loader,
    setCurrentPage,
  } = useContext(DataContext);

  const limit = 100;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [jd, setJd] = useState("");
  const [selectedWorkNature, setSelectedWorkNature] = useState("");

  useEffect(() => {
    setCurrentPage(1);
    jobPaginationApi(1, limit);
  }, []);

  const onKeyUpHandler = (e) => {
    const inputValue = e.target.value;

    // Update search input state
    setSearchInput(inputValue);

    // Check if the length of the input is at least 4 characters or it's empty
    if (inputValue.length >= 4 || inputValue.length === 0) {
      searchJobApi(inputValue);
    }
  };

  // const onKeyUpHandler = (e) => {
  //   searchJobApi(e.target.value);
  // };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...job].sort((a, b) => {
      const valueA = (a[property] || "").toString();
      const valueB = (b[property] || "").toString();
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

    setJob(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };
  //------applynow functionality
  const handleApplyJobDisabled = () => {
    // updateTechnologyEnableApi(item?._id);
    setApplyJobModal(false);
  };
  const handleOpenModal = () => {
    setApplyJobModal(true);
  };
  //------ view functionality
  const handleJDModalDisabled = () => {
    setViewJDModal(false);
  };

  const handleOpenJDModal = (id) => {
    setViewJDModal(true);
    setJd(id);
  };
  const filteredJobs = job.filter(
    (item) =>
      selectedWorkNature === "" || item.worknature === selectedWorkNature
  );
  return (
    <>
      <ToastContainer />
      {deleteBtn ? (
        <DeleteModal deleteApi={deleteJobApi} deleteId={deleteBtnId} />
      ) : (
        ""
      )}
      {applyJobModal ? (
        <ApplyJobModal
          selectedJob={selectedJob}
          apiFunction={handleApplyJobDisabled}
        />
      ) : null}
      {viewJDModal ? (
        <ViewJDModal jd={jd} apiFunction={handleJDModalDisabled} />
      ) : null}
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 flex justify-between space-x-2 items-start flex-wrap">
            <h1 className="text-xl text-[#202223] font-semibold">Job List</h1>
            {/* <div className=" flex justify-between space-x-2 pt-4 items-start flex-wrap"> */}
            <div className="flex gap-1">
              <select
                className="p-2 text-sm mx-4 bg-transparent border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none"
                value={selectedWorkNature}
                onChange={(e) => setSelectedWorkNature(e.target.value)}
              >
                <option selected value="">
                  Select Work Nature
                </option>
                <option value="work from home">Work From Home</option>
                <option value="hybrid">Hybrid</option>
                <option value="work from office">Work From Office</option>
              </select>

              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
          {loader ? (
            <Loader />
          ) : filteredJobs?.length > 0 ? (
            <>
              <div className="h-[75vh] overflow-y-scroll overflow-x-scroll">
                <table className="w-full table-auto overflow-hidden border-collapse bg-white shadow-md  rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className="  bg-theme-light text-theme-color text-xs font-normal py-2 ">
                      <th className=" px-4 py-2 flex items-center">
                        <div className="flex items-center gap-4">
                          <p>Job Title</p>
                          <div className="px-2 ">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "jobtitle",
                                  "asc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "jobtitle",
                                  "desc"
                                )}`}
                                onClick={() => sortData("jobtitle")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Experience</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%] ${getSortArrowClassName(
                                  "totalExp",
                                  "asc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "totalExp",
                                  "desc"
                                )}`}
                                onClick={() => sortData("totalExp")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4">
                        <div className="flex items-center gap-4">
                          <p>Position</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "positions",
                                  "asc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "positions",
                                  "desc"
                                )}`}
                                onClick={() => sortData("positions")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className="w-[30px] px-2 ">
                        <div className="flex items-center gap-4">
                          <p>Work Nature</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "worknature",
                                  "asc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "worknature",
                                  "desc"
                                )}`}
                                onClick={() => sortData("worknature")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4">
                        <div className="flex items-center gap-4">
                          <p>Location</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "location",
                                  "asc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "location",
                                  "desc"
                                )}`}
                                onClick={() => sortData("location")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4">
                        <div className="flex items-center gap-4">
                          <p>Rate</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "rate",
                                  "asc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px] ${getSortArrowClassName(
                                  "rate",
                                  "desc"
                                )}`}
                                onClick={() => sortData("rate")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4  text-center rounded-tr-lg">
                        Applied
                      </th>
                      <th className=" px-4 text-center rounded-tr-lg">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {filteredJobs?.map((item) => (
                      <tr className="border-t text-left text-[16px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                        <td className="px-4 py-2 text-start">
                          {item?.jobtitle}
                        </td>
                        <td className="px-2 py-2">
                          {item.hasOwnProperty("totalExp")
                            ? `${item?.totalExp?.years}. ${item?.totalExp?.months}`
                            : "0.0"}
                        </td>
                        <td className="px-4 py-2">{item?.positions}</td>
                        <td className="w-[30px] px-2 py-2">
                          {item?.worknature}
                        </td>
                        <td className="px-4 py-2">{`${item?.location}`}</td>
                        <td className="px-4 py-2">{item?.rate}</td>
                        <td className="px-4 py-2">
                          {item?.appliedcandidates?.length}
                        </td>
                        <td className="px-4 py-2">
                          <div className="flex items-center  justify-center  gap-2 text-black ">
                            <span
                              className="text-theme-color hover:underline text-sm"
                              onClick={() => {
                                handleOpenJDModal(item.jobdescription);
                              }}
                            >
                              View JD |
                            </span>
                            <span
                              className="text-theme-color hover:underline text-sm"
                              onClick={() => {
                                handleOpenModal();
                                setSelectedJob(item);
                              }}
                            >
                              Apply Now
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {/*<tr className=" border-t text-left text-sm text-[#202223] font-normal group hover:shadow-tableRowsShadow cursor-pointer">
                  <td className=" px-4 py-2 rounded-bl-lg"><img src={profileImg} className="w-16 h-16 rounded-[2px]"/></td>
                  <td className=" px-4 py-2">Mr.</td>
                  <td   className="px-4 py-2">James Devine</td>
                  <td   className="px-4 py-2 ">Principal Solutions Architect</td>
                  <td   className="px-4 py-2 ">Amazon Web Services</td>
                  <td className='rounded-br-lg'> <div className="flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color"> <FiTrash/> <FiEdit2/> </div></td>
                 
                    </tr>*/}
                  </tbody>
                </table>
              </div>
              {/* )} */}
              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount} results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={jobPaginationApi}
                />
              </div>
            </>
          ) : null}
          {((!loader && !job) || job.length == 0) && <NoData />}
        </div>
      </div>
    </>
  );
};

export default JobList;
