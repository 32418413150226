import React, { useContext } from 'react'
import { DataContext } from '../../context/Datastate'
import thku from "../Assets/thankyou2.png"
import { useNavigate } from 'react-router-dom'
import {RiCloseCircleFill} from 'react-icons/ri';


const ApplyPopup = () => {
  const navigate = useNavigate()
  const {setApplyPopup} = useContext(DataContext)
  const handleClose = ()=>{
    setApplyPopup(false);
    navigate("/jobs")
  }

  return (
    <>
    <div className="fixed top-0 h-screen left-0 w-full bg-[#00000062] z-[8]">
        <div className="absolute top-1/2 left-1/2 -translate-x-2/4 z-[9] -translate-y-2/4">
                    <div className="bg-white p-4 rounded z-10">
                        <RiCloseCircleFill className="absolute top-4 right-4" onClick={handleClose}/>

                        <div className=" flex justify-center">
                           <img src={thku} className='w-[300px]' alt="" />
                        </div>
                        <div className="py-3 text-center">

                            <span className=" block py-2 text-[20px] sm:text-[13px] xs:text-[12px] ">
                               Application Submitted! 
                            </span>
                            <p className="text-[#475467] text-[14px] sm:text-[13px] xs:text-[12px]" style={{textWrap: "balance"}}>Thank you for applying. We have received your application and will be in touch if you are selected to move forward.</p>
                        </div>
                        <div className="buttons pb-4 justify-center flex gap-3 mt-6 xs:mt-4 sm:mt-5 w-full">
                            {/* <button
                                className="bg-theme-color w-[25%] rounded-md font-medium md:text-[13px] sm:text-[12px] xs:text-[11px] text-white p-[.4rem_1rem]"
                                onClick={() => handleClose()}
                            >
                                Go Back To Login
                            </button> */}
                        </div>

                    </div>
                </div>
            </div>
    </>
  )
}

export default ApplyPopup