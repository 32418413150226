import React, { useState } from 'react'
import { CustomLabel } from '../atoms'
import VideoRecorder from '../VideoRecod'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fetchUtil from '../../utils/fetch.util'
import { UPLOAD_VIDEO } from '../../constants/apiEndPoints'
import { toast } from 'react-toastify'

const UploadVideo = () => {
  const [state, setState] = useState({ videoIntro: {} });
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fileSize = 1024 * 1024 * 50; //50MB

  const handleUpload = () => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(state).forEach(key => formData.append(`${key}`, state[key]));

    if (params.candidateID) {
      if (state.videoIntro?.size <= fileSize) {
        fetchUtil.PUT(`${UPLOAD_VIDEO}/${params.candidateID}`, formData, 'formData')
          .then(() => {
            toast.success("Video uploaded successfully");
            setTimeout(() => {
              navigate('/jobs');
            }, 1000);
          })
          .catch(err => console.log(err))
          .finally(() => setLoading(false));
      } else toast.error('File size should be less than 50MB');
    }
  }

  return (
    <>
      <header className="bg__banner bg-bannerGradient py-6 px-4 text-left">
        <h1 className="heading_clamp font-semibold text-white pb-2">
          Find the perfect job
        </h1>
        <p className="para_clamp font-normal text-white">
          Looking for jobs? Browse our latest job openings to view & apply to
          the best job today!
        </p>
      </header>
      <div className="h-[calc(100vh-11rem)] rounded-lg bg-white shadow-openJobsShadow p-4">
        <Link to={'/jobs'} className='text-theme-color underline'>Browser jobs</Link>
        {/* <div className="flex flex-col gap-4"> */}
        <div className='flex gap-10 mt-5'>
          <div className='w-1/3'>
            <p>
              We'd love to get to know you better! <br />Upload a short video introducing yourself to our hiring team. <br /> This is your chance to showcase your personality and highlight why you're the ideal candidate for the role.
            </p>
            <p className='mt-5'><b>Video Guidelines:</b></p>
            <ul className='list-disc ml-5'>
              <li>Tell us about your background, interests, and what excites you about this opportunity</li>
              <li>Feel free to get creative, but please keep the content professional</li>
            </ul>
          </div>
          <div className="w-2/3 max-w-[32rem] h-[50vh] container">
            {/* <h2 className="font-semibold text-lg text-left mt-5">Upload video</h2>
          <CustomLabel subHeading={'(please record a small clip)'}>A brief intro</CustomLabel> */}
            <VideoRecorder setState={setState} fileKey={'videoIntro'} onUpload={handleUpload} loading={loading} />
          </div>
        </div>
      </div>
      <footer className="bg-theme-color text-white py-2 px-4 text-center">
        <p>Copyright © {new Date().getFullYear()} GetDevelopers</p>
      </footer>
    </>
  )
}

export default UploadVideo
